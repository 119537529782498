const firebaseConfig = {
    apiKey            : "AIzaSyAfoDPDmg2loXTJ7hJVNZkRemA8_ChRDe0",
    authDomain        : "arioso-admin.firebaseapp.com",
    databaseURL       : "https://arioso-admin.firebaseio.com",
    projectId         : "arioso-admin",
    storageBucket     : "arioso-admin.appspot.com",
    messagingSenderId : "339262861811",
    appId             : "1:339262861811:web:8f86dfb7e16607a8"
  };

export default firebaseConfig;