/* 
https://www.youtube.com/watch?v=7sJZi0grFR4
6.57

es6 class function
const fun = (x) => {}
vs
fun: f(x)
vs 
fun(x)

Refractor whole function component xx
*/

import React, { useEffect, useState } from 'react'
import { Inventory$, TaxRate$, SettingsDefault$ } from 'utilities/Streams';
import {PurchaseOrder$} from 'utilities/Pipes';
import { Container, Card, CardContent, Typography, TextField, Paper, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, makeStyles, Badge, IconButton, Button, FormControlLabel, Checkbox, Grid, CircularProgress, Divider } from '@material-ui/core';
import { PriceFloat, Percent } from 'common/utils/UtilityFunctions';
import firebase from 'utilities/Firebase';
import { Delete, Warning, CheckCircleOutline, Save, InsertDriveFile, ShoppingBasket, ShoppingCart } from '@material-ui/icons';
import ComponentStyle from './styles';
import MakePDF from './MakePDF';
import SaveInvoice from './SaveInvoice';
import MaterialTable from 'material-table';
import tableIcons from 'common/Material-Table_Icons';


import set from 'lodash/set';
import { InvoiceState } from './rm_InvoiceState';

const useStyles = ComponentStyle;

export default function NewInvoice({ match }) {
	const classes = useStyles();
	const [Invoice_State, SetInvoice_State] = useState(null);
	const [InventoryList, SetInventoryList] = useState(undefined);
	const [InvoiceNumber, setInvoiceNumber] = useState(0);
	const [TaxRate, SetTaxRate] = useState(null);

	const [LoadingPO, setLoadingPO] = useState(true);
	const [LoadingTax, setLoadingTax] = useState(true);
	const [LoadingInventory, setLoadingInventory] = useState(true);
	const [LoadingInvoiceNumber, setLoadingInvoiceNumber] = useState(true);


	const SetFromPO = (PurchaseOrder, InvoiceNum) => {
		SetInvoice_State({
			Date             : firebase.firestore.Timestamp.fromDate(new Date()),
			BackOrdered      : false,
			Finished         : false,
			Cancelled        : false,
			Paid             : false,
			ShippingHandling : 0.00,
			Discount         : 0.00,
			PurchaseOrderID : PurchaseOrder.PurchaseOrderID,
			Cart            : PurchaseOrder.Cart.map(CartItem => ({
				...CartItem,
				Shipped   : 0,
				BackOrder : 0,
				UnitPrice : CartItem.Price.DealerPrice,
			})),
			CompanyID     : PurchaseOrder.CompanyID,
			Company       : PurchaseOrder.Company,
			InvoiceNumber : InvoiceNum,
			/* Change Invoice Number */
		})
	}

	useEffect(() => {
		let POSub;
		
		let TaxSub = TaxRate$.subscribe(res => {
			SetTaxRate(res)
			setLoadingTax(false);
		});
		let InventorySub = Inventory$.subscribe(res => {
			SetInventoryList(res);
			setLoadingInventory(false);
		});

		let SettingsSub = SettingsDefault$.subscribe(SettingsRes => {
			setInvoiceNumber(SettingsRes.InvoiceNumber);
			setLoadingInvoiceNumber(false);

			POSub = PurchaseOrder$.subscribe(PORes => {
				SetFromPO(PORes.find(PurchaseOrder => PurchaseOrder.PurchaseOrderID === match.params.PurchaseOrderID), SettingsRes.InvoiceNumber);
				setLoadingPO(false);
			}) || 
			(
				SetInvoice_State({
				Cart             : {},
				CompanyID        : null,
				InvoiceNumber    : null,
				Date             : firebase.firestore.Timestamp.fromDate(new Date()),
				BackOrdered      : false,
				Finished         : false,
				Cancelled        : false,
				Paid             : false,
				ShippingHandling : 0.00,
				Discount         : 0.00,
				}) &&
				setLoadingPO(false)
			);

		})
		return (() => {
			POSub.unsubscribe();
			TaxSub.unsubscribe();
			InventorySub.unsubscribe();
			SettingsSub.unsubscribe();
		})
	}, [])


	const handleChange = (InventoryID, field, value) => {
		SetInvoice_State(Invoice_State => ({
			...Invoice_State,
			Cart: [
				...Invoice_State.Cart.map(CartItem => 
					CartItem.InventoryID === InventoryID ? ({...CartItem, [field]: value}) : {...CartItem})
			]
		}));
	}

	const handleChangeQty = evt => {
		if (InstantShipping) handleChange(evt.target.dataset.inventoryid, 'Shipped', parseInt(evt.target.value))
		else handleChange(evt.target.dataset.inventoryid, 'Quantity', parseInt(evt.target.value));
	};

	const handleChangeBO = evt => handleChange(evt.target.dataset.inventoryid, 'BackOrder', parseInt(evt.target.value));

	const handleChangeShipped = evt => {
		
		if (InstantShipping) handleChange(evt.target.dataset.inventoryid, 'Quantity', parseInt(evt.target.value))
		else handleChange(evt.target.dataset.inventoryid, 'Shipped', parseInt(evt.target.value));
		
	};

	const handleChangePrice = evt => handleChange(evt.target.dataset.inventoryid, 'UnitPrice', parseFloat(evt.target.value));

	const handleChangeShipping = evt => handleChangeInvoice('ShippingHandling', evt.target.value);
	const handleChangeDiscount = evt => handleChangeInvoice('Discount', evt.target.value);

	const handleChangeInvoice = (field, value) => SetInvoice_State({
		...Invoice_State,
		[field]: value
	});


	const handleRemove = InventoryID => {
		SetInvoice_State({
			...Invoice_State,
			Cart: [
				...Invoice_State.Cart.filter(CartItem => CartItem.InventoryID !== InventoryID)
			]
		})
	}

	const TextFieldFinancial = (value, change, id) => (
		<TextField variant='filled'
			inputProps={{ style: {textAlign: 'right'} }}
			className={classes.CardFieldFloat}
			hiddenLabel
			margin='dense'
			value={value}
			id = {id}
			onChange={change}
			InputProps = {{startAdornment: <InputAdornment position='start'>$</InputAdornment>}}/>
	)

	const TextFieldGeneral = (value, change, id) => (
		<TextField
			inputProps={{ style: {textAlign: 'center'} }}
			variant='filled'
			className={classes.CartFieldInteger}
			margin='dense'
			id={id}
			value={value}
			onChange={change} />
		)

	const InvoiceTextField = (InventoryID, Value, handleChangeFcn, financial = false) => {
		if (financial) 
			return (
				<TextField
					value      = {Value}
					className  = {classes.CartFieldFloat}
					inputProps = {{ style: {textAlign: 'center'}, 'data-inventoryid': InventoryID, type: 'number' }}
					variant    = 'filled'
					margin     = 'dense'
					onChange   = {handleChangeFcn}
					InputProps = {{startAdornment: (<InputAdornment position='start'> $ </InputAdornment>)}} />)
		else return (
			<TextField
				value      = {Value}
				className  = {classes.CartFieldInteger}
				inputProps = {{ style: {textAlign: 'center'}, 'data-inventoryid': InventoryID, type: 'number' }}
				variant    = 'filled'
				margin     = 'dense'
				onChange   = {handleChangeFcn} />)
	}

	/* InputProps={{startAdornment: (<InputAdornment position='start'> $ </InputAdornment>)}}  */

	const CheckoutTextField = (Label, FieldType, Value, handleChangeFcn, financial = true, props) => {
		if (financial) return (
			<TextField
				label      = {Label}
				value      = {Value}
				className  = {classes.CheckoutFieldFloat}
				inputProps = {{ style: {textAlign: 'center'}, 'data-fieldtype': FieldType, type: 'number' }}
				variant    = 'filled'
				margin     = 'normal'
				onChange   = {handleChangeFcn}
				InputProps = {{startAdornment: (<InputAdornment position='start'> $ </InputAdornment>)}}
				{...props} />)
		else return (
			<TextField
				label      = {Label}
				value      = {Value}
				className  = {classes.CartFieldInteger}
				inputProps = {{ style: {textAlign: 'center'}, 'data-fieldtype': FieldType, type: 'number' }}
				variant    = 'filled'
				margin     = 'normal'
				onChange   = {handleChangeFcn} 
				{...props} />)
	}

	const FilledTextField = ({adornment, ...rest}) => {
		if (adornment) {
			return <TextField
				inputProps={{ style: {textAlign: 'right'} }}
				variant='filled'
				className={classes.CartFieldFloat}
				{...rest}
				hiddenLabel
				margin='dense'
				InputProps = {{startAdornment: <InputAdornment position='start'>{adornment}</InputAdornment>}} />
		} else {
			return <TextField
				inputProps={{ style: {textAlign: 'right'} }}
				variant='filled'
				className={classes.CartFieldInteger}
				{...rest}
				hiddenLabel
				margin='dense' />
		}
	}

	

	const BillToJoin = () => `${Invoice_State.Company.BillTo.Name.First} ${Invoice_State.Company.BillTo.Name.Last}
${Invoice_State.Company.BillTo.Address}
${Invoice_State.Company.BillTo.City} ${Invoice_State.Company.BillTo.ProvinceState}
${Invoice_State.Company.BillTo.Country} ${Invoice_State.Company.BillTo.PostalCode}`;

	const ShipToJoin = () => `${Invoice_State.Company.ShipTo.Name.First} ${Invoice_State.Company.ShipTo.Name.Last}
${Invoice_State.Company.ShipTo.Address}
${Invoice_State.Company.ShipTo.City} ${Invoice_State.Company.ShipTo.ProvinceState}
${Invoice_State.Company.ShipTo.Country} ${Invoice_State.Company.ShipTo.PostalCode}`;
	
	const ItemCheck = (Stock, Quantity, BackOrder, Shipped) => {
		if (Quantity === 0) return false;
		if (Quantity !== BackOrder + Shipped) return false;
		if (Stock < Shipped) return false;
		else return true;
	}

	const InvoiceTaxRates = () => TaxRate[Invoice_State.Company.Tax.TaxID];

	
	const handleChangeInvoiceNumber = evt => {
		handleChangeInvoice('InvoiceNumber', evt.target.value);
		setInvoiceNumber(evt.target.value);
	}
	
	const [SearchFieldValue, SetSearchFieldValue] = useState('');
	const handleSearchChange = evt => SetSearchFieldValue(evt.target.value);
	
	const [InstantShipping, SetInstantShipping] = useState(false);
	const handleInstantShipping = evt => {
		SetInstantShipping(InstantShipping => !!!InstantShipping);
		if (InstantShipping) {	
			SetInvoice_State({...Invoice_State, Cart: Invoice_State.Cart.map(CartItem => ({...CartItem, Shipped: CartItem.Quantity}))})
		}
	}

	const handleFinishedCheck = evt => SetInvoice_State({...Invoice_State, Finished: evt.target.checked, Paid: evt.target.checked});

	const SubTotal = Invoice => PriceFloat(Invoice.Cart.reduce((acc, val) => acc += (val.UnitPrice * val.Quantity), 0));

	const Total = Invoice => {};

	if (LoadingPO || LoadingTax || LoadingInventory) return(<h1>Loading... <CircularProgress /></h1>)
	else {
		const TableData = Invoice_State.Cart.map(({Metadata: {ImgURL}, InventoryID, Title, Quantity, BackOrder, Shipped, Price: {DealerPrice}, UnitPrice, Internal: {Stock}}) => ({
				Cover: ImgURL[Object.keys(ImgURL)[0]] || '',
				SKU: InventoryID,
				Title,
				Quantity,
				BackOrder: BackOrder || 0,
				Shipped: Shipped || 0,
				UnitPrice: UnitPrice || DealerPrice,
				Total: (Quantity * (UnitPrice || DealerPrice)) || 0.00,
				Stock: Stock || 0,
		}));
		return (
			<Container className={classes.Container}>
				<Card className={classes.CardPanel}>
					<CardContent>
						<Grid container direction='row' spacing={3}>
							<Grid item xs={12} sm={6}>
								<Typography variant='h5'>Invoice Information</Typography>
								<Divider />
								<TextField
									margin='normal'
									variant='filled'
									label='Invoice Number'
									className={classes.InvoiceInfo}
									value={Invoice_State.InvoiceNumber || InvoiceNumber}
									onChange={handleChangeInvoiceNumber} />
								<TextField
									margin='normal'
									variant='filled'
									label='Invoice Date'
									className={classes.InvoiceInfo}
									value={new Date().toDateString()}
									disabled />
								<TextField
									margin='normal'
									variant='filled'
									label='Purchase Order ID'
									className={classes.InvoiceInfo}
									value={Invoice_State.PurchaseOrderID}
									disabled />
								<TextField
									margin='normal'
									variant='filled'
									label='Purchase Order Date'
									className={classes.InvoiceInfo}
									value={new Date().toDateString()}
									disabled />	
								<FormControlLabel
									label='BackOrder Invoice'
									control={
										<Checkbox
											checked={false}
											disabled />} />
								<h6>Date</h6>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography variant='h5'>Customer Information</Typography>
								<Divider />
								<Grid container item>
									<Grid item xs={12} sm={6}>
										<TextField
											label='Bill To'
											multiline
											rows='5'
											className={classes.CompanyTextArea}
											defaultValue={BillToJoin()}
											margin='normal'
											variant='filled'
											disabled={true} />
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											label='Ship To'
											multiline
											rows='5'
											className={classes.CompanyTextArea}
											defaultValue={ShipToJoin()}
											margin='normal'
											variant='filled'
											disabled={true} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Card className={classes.CardPanel}>
					<CardContent>
						<Grid item xs>
						<h6>JS Fuzzy Search + Autocomplete Component</h6>
							<Typography variant='h5'>Actions</Typography>
							<Divider />
							<TextField
								margin='normal'
								variant='filled'
								label='Search Item'
								className={classes.InvoiceInfo}
								value={SearchFieldValue}
								onChange={handleSearchChange} />
							<FormControlLabel
								label='Instant Shipping'
								control={
									<Checkbox
										checked={InstantShipping}
										onChange={handleInstantShipping}
										value='Instant Shipping' />} />
						</Grid>
					</CardContent>
				</Card>

				<Card className={classes.CardPanel}>
					<CardContent>
						<Grid item xs>
							<MaterialTable
								icons={tableIcons}
								title='Invoice Items'
								data={TableData}
								columns={[
									{ title: 'Status', field: 'Status', render: ({Stock, Quantity, BackOrder, Shipped}) => ItemCheck(Stock, Quantity, BackOrder, Shipped) ? (<CheckCircleOutline color='primary'/>) : (<Warning color='secondary'/>), headerStyle: {textAlign: 'center'}, cellStyle: {textAlign: 'center'}},
									{ title: 'Cover', field: 'Cover', render: ({Cover, Stock}) => (
										<Badge
											color={Stock ? 'primary' : 'secondary'}
											badgeContent={Stock || '0'}
											className={Stock < 0 && classes.BadgeNegative} >
										<img src={Cover} height='50' width='auto'/>
										</Badge>
									)},
									{ title: 'SKU', field: 'SKU'},
									{ title: 'Title', field: 'Title'},
									{ title: 'Quantity'   , field: 'Quantity'  , render: ({Quantity, SKU}) => (InvoiceTextField(SKU, Quantity  || 0   , handleChangeQty           ))},
									{ title: 'Back Order' , field: 'BackOrder' , render: ({BackOrder , SKU}) => (InvoiceTextField(SKU, BackOrder || 0       , handleChangeBO            ))},
									{ title: 'Shipped'    , field: 'Shipped'   , render: ({Shipped   , SKU}) => (InvoiceTextField(SKU, Shipped   || 0       , handleChangeShipped       ))},
									{ title: 'Unit Price' , field: 'UnitPrice' , render: ({UnitPrice , SKU}) => (InvoiceTextField(SKU, UnitPrice || 0.00, handleChangePrice   , true))},
									{ title: 'Total', field: 'Total', render: ({Quantity, UnitPrice}) => <Typography variant='body1'>$ {(Quantity * UnitPrice).toFixed(2) || 0.00}</Typography>},
								]}
								actions={[
									{
										icon: () => <Delete />,
										tooltip: 'Delete Item',
										onClick: (event, rowData) => handleRemove(rowData.SKU)
									}
								]}
								options={{pageSize: Invoice_State.Cart.length}}
								style={{boxShadow:'none'}} />
						</Grid>
					</CardContent>
				</Card>

				{/* [Save] [Commmit] */}
				<Grid container direction='row' spacing={1}>
					<Grid item xs={12} sm={6}>
						<Card className={classes.CardPanel}>
							<CardContent>
								<Typography variant='h5'>Checkout</Typography>
								<Divider />
								<div>
									<FormControlLabel
										control={
											<Checkbox
												checked={Invoice_State.Finished}
												onChange={handleFinishedCheck}
												value="Finished"
												color="primary"/>
										}
										label="Finished"/>
									<Button
										variant='contained'
										color='primary'
										className={classes.Button}
										onClick={() => SaveInvoice(Invoice_State, true)} >
										Commit
										<Save className={classes.RightIcon} />
									</Button>
									<Button
										variant='contained'
										color='primary'
										className={classes.Button}
										onClick={() => SaveInvoice(Invoice_State, false)} >
										Save
										<Save className={classes.RightIcon} />
									</Button>
								</div>
								<div>
									<Button
										variant='contained'
										color='primary'
										className={classes.Button}
										onClick={() => MakePDF(Invoice_State, TaxRate)} >
										Make PDF
										<InsertDriveFile className={classes.RightIcon} />
									</Button>
								</div>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6} container direction='column'>
						<Card className={classes.CardPanel}>
							<CardContent>
								<Typography variant='h5'>Summary</Typography>
								<Divider />
								{CheckoutTextField('Sub Total', 'SubTotal', SubTotal(Invoice_State), null, true, {disabled: true})}
								{CheckoutTextField('Shipping and Handling', 'Shipping', Invoice_State.ShippingHandling, handleChangeShipping, true)}
								{CheckoutTextField('Discount', 'Discount', Invoice_State.Discount, handleChangeDiscount, true)}								
								{Object.keys(InvoiceTaxRates()).map(RateName => (
									<>
									{CheckoutTextField(`${RateName} - ${Percent(InvoiceTaxRates()[RateName])} %`, `Tax-${RateName}`, (InvoiceTaxRates()[RateName] * (SubTotal(Invoice_State) + Invoice_State.ShippingHandling + Invoice_State.Discount)).toFixed(2), null, true, {disabled: true})}
									</>
								))}
								{CheckoutTextField('Total', 'Total', 
								(SubTotal(Invoice_State) + Invoice_State.ShippingHandling + Invoice_State.Discount) * 1 +
								Object.values(InvoiceTaxRates()).reduce((acc, val) => acc = (parseFloat(acc) + parseFloat(val) * parseFloat(SubTotal(Invoice_State))).toFixed(2), 0.00)*1,
								null, true, {disabled: true})}
							</CardContent>
						</Card>
					</Grid>
				</Grid>


{/*
	Paganini For Two: 41.50
	Chopin: The Nocturnes: 54.50
	Sarasate: Carmen Fantasie: 	54.50
	Tchaikovsky: Violin Concerto: 47.50
*/}


{/* 
M2S728 STOCK 	5 37.80Cost
S-BPG62402 		5 22.28Cost
 */}

{/* <Card className={classes.CardPanel}>
					<CardContent>
						<Table className={classes.CartTable}>
							{CartTableHead}
							<TableBody>
								{Invoice_State.Cart.map(({Quantity, BackOrder, Shipped, InventoryID, Title, Metadata, Internal, UnitPrice}) => (
										<TableRow>
											<TableCell>
												<IconButton onClick={() => handleRemove(InventoryID)} >
													<Delete />
												</IconButton>
											</TableCell>
											<TableCell>
												{ItemCheck(Quantity, BackOrder, Shipped) ? (<CheckCircleOutline />) : (<Warning />)}
											</TableCell>
											<TableCell>
												<Badge
													className={classes.Badge}
													color={Internal.Stock ? 'primary' : 'secondary'}
													badgeContent={Internal.Stock || '0'} >
													{TextFieldGeneral(Quantity, handleChangeQty, InventoryID)}
												</Badge>
											</TableCell>
											<TableCell>
												{TextFieldGeneral(BackOrder, handleChangeBO, InventoryID)}
											</TableCell>
											<TableCell>
												{TextFieldGeneral(Shipped, handleChangeShipped, InventoryID)}
											</TableCell>
											<TableCell>
												<img src={Object.values(Metadata.ImgURL)[0]} width='100' height='auto' />
											</TableCell>
											<TableCell>{InventoryID}</TableCell>
											<TableCell>
												<span className='TableMoney'>
													{Title}
												</span>
											</TableCell>
											<TableCell>
												{// {TextFieldFinancial(UnitPrice, handleChangePrice, InventoryID)}}
												<TextField
													variant='filled'
													inputProps={{
														style: {textAlign: 'right'}
													}}
													className={classes.CardFieldWide}
													hiddenLabel
													margin='dense'
													value={UnitPrice}
													id={InventoryID}
													onChange={handleChangePrice}
													InputProps={{
														startAdornment: (<InputAdornment position='start'> $ </InputAdornment>)
													}} />
											</TableCell>
											{// <TableCell>{FilledTextField({value:{UnitPrice}, adornment:'$', id:{InventoryID},onChange:{handleChangePrice}})}</TableCell> }
											<TableCell>
												<FilledTextField
													className={classes.FieldRightAlign}
													value={PriceFloat(Quantity * UnitPrice)}
													adornment='$'
													disabled />
											</TableCell>
										</TableRow>
									)
								)} */ }
{/*
	{TextFieldFinancial(Invoice_State.ShippingHandling, handleChangeShipping, 'ShippingHandling')}

	{TextFieldFinancial(Invoice_State.Discount, handleChangeDiscount, 'Discount')}
	{Object.keys(InvoiceTaxRates()).map(RateName => (
		<Typography>
			{RateName}:{' '}
			{Percent(InvoiceTaxRates()[RateName])} %
		</Typography>
	))}
	<FilledTextField
		value={
			PriceFloat(
				Object.keys(InvoiceTaxRates()).reduce((acc, idx) =>
					(acc += InvoiceTaxRates()[idx]), 0) *
					(parseFloat(SubTotal(Invoice_State) || 0) +
					parseFloat(Invoice_State.ShippingHandling || 0) -
					parseFloat(Invoice_State.Discount || 0))
			)
		}
		adornment='$'
		disabled />
		<FilledTextField
		value={
			PriceFloat(
				(Object.keys(InvoiceTaxRates()).reduce((acc, idx) => (
					acc += InvoiceTaxRates()[idx]), 0) + 1) *
					(parseFloat(SubTotal(Invoice_State) || 0) +
					parseFloat(Invoice_State.ShippingHandling || 0) -
					parseFloat(Invoice_State.Discount || 0)
				)
			)
		}
		adornment='$'
		disabled /> */}
				
			</Container>
		)
	}
}