import { makeStyles } from "@material-ui/styles";

const ComponentStyle = makeStyles(theme =>  ({
	Container: {
		maxWidth: 'none',
	},
	Paper: {
		padding: '0.5rem',	
	},

	InvoiceInfo: {
		margin: '0.5rem 1rem',
	},

	FilledInput : {
		width: '100px',
		textAlign: 'right',
		margin:0
	},

	CompanyTextArea : {
		width: '90%',
	},

	CardPanel : {
		margin: '1rem',
		/* display: 'flex' */
	},

	CartTable: {
		tableLayout: 'auto'
	},

	CartFieldInteger: {
		width: '5rem',
		textAlign: 'center',
	},

	CartFieldFloat: {
		width: '7rem',
	},

	CheckoutFieldFloat: {
		width: '10rem',
	},

	RightIcon: {
		marginLeft: '0.7rem'
	},

	Button: {
		margin: '0.5rem'
	},

	Badge: {
		top: '0.6rem',
		right: '0.2rem'
	},


	CardFieldWide: {
		width: '6rem'
	},

	FieldRightAlign: {
		textAlign: 'right'
	},

	fab: {
		position: 'fixed',
		bottom: '1.5rem',
		right: '1.5rem',
	},
	BadgeNegative: {
		backgroundColor: 'yellow',
	},


	})
)

export default ComponentStyle;