/* 
    //https://fonts.google.com/specimen/PT+Sans
    var PTSans = "AAEAAAAUAQA..."; //shortened. see demoUsingTTFFont in examples/js/basic.js for full base64 encoded ttffile
    var doc = new jsPDF();

    doc.addFileToVFS("PTSans.ttf", PTSans);
    doc.addFont('PTSans.ttf', 'PTSans', 'normal');

    doc.setFont('PTSans'); // set font
    doc.setFontSize(10);
    doc.text("А ну чики брики и в дамки!", 10, 10);
    
    doc.save('test.pdf');

*/
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { PriceString, Percent, PriceFloat } from 'common/utils/UtilityFunctions';

//{ Constants
const TXT_COMPANY_ADDR = "Arioso Music Ltd.\r\n11420 Blacksmith Place\r\nRichmond, BC V7A 4X1\r\nwww.arioso-records.com (604) 727-5585";
const TXT_DISCLAIMER   = 'Price is in Canadian dollars.  Please make cheque payable to Arioso Music Ltd. \r\n'
							+ 'Order will be shipped after notification of payment.\r\nAny back orders shall be kept on our system until fulfilled.';

const STYLE_HEADER_BGCOLOR = [41, 128, 186]; //BLUE [26, 189, 156]; //TEAL
const FLOAT_HEADER_PADDING = 4.5;
const STYLE_COLS_HEADER4 = {
		qty:    {columnWidth: 30, halign: 'center', valign: 'top'},
		bo:     {columnWidth: 30, halign: 'center', valign: 'top'},
		ship:   {columnWidth: 33, halign: 'center', valign: 'top'},
		sku:    {columnWidth: 75, valign: 'top'},
		desc:   {columnWidth: 262, valign: 'top', overflow: 'linebreak'},
		unitP:  {columnWidth: 45, halign: 'right', valign: 'top'},
		total:  {columnWidth: 55, halign: 'right', valign: 'top'}
	};

const STYLE_THEME_STRIPED     = {bodyStyles        : {fillColor: 244, overflow: 'linebreak', valign: 'top'}, rowStyles: {overflow: 'linebreak'},columnStyles: STYLE_COLS_HEADER4 };

/* {bodyStyles: {valign: 'middle', minCellHeight: 30, overflow: 'linebreak'}}, {columnStyles: STYLE_COLS_HEADER4}, {rowStyles: {overflow: 'linebreak'}}, */
const STYLE_THEME_STRIPED_ALT = {alternateRowStyles: {fillColor: 255, overflow: 'linebreak', valign: 'top'}, rowStyles: {overflow: 'linebreak'}, columnStyles: STYLE_COLS_HEADER4};

const STYLE_FONT_DIM_CHECKOUT         = 12;
const STYLE_FONT_FONTSTYLE_DISCLAIMER = 'itlaic';
const STYLE_FONT_DIM_DISCLAIMER       = 8;
//}

function makeTable(_doc, _startY, _body, ...args) {
	let tableData = {};
	tableData['startY'] = _startY;
	tableData['body']   = _body;
	args.map(arg => {
		Object.keys(arg).map(prop => { tableData[prop] = arg[prop]; })
	});
	_doc.autoTable(tableData);
}

export default function MakePDF(Invoice, TaxRate) {
	let doc = new jsPDF('p', 'pt', 'letter');

	//{ Style Defaults 
		doc.setFontSize(12);
		doc.setTextColor(0);
		doc.setFontStyle('bold');
	//}

	//{ Header 1: Company Address
	let header1 = [];
	/* header1.push({data: ''}); */
	header1.push({text: {content: TXT_COMPANY_ADDR, styles: {fontStyle: 'bold', fillColor: 255}}});
	header1.push({data: ''})
	/* header1.push({data: TXT_COMPANY_ADDR}) */
	/* let header1 = [{text: {content: 'hi', styles: {valign: 'middle', halign: 'center', fontStyle: 'bold', fillColor: 255}}}]; */
	/* header1[0]['text'] = {content: TXT_COMPANY_ADDR, styles: {valign: 'middle', halign: 'center', fontStyle: 'bold', fillColor: 255}}; */
	//}

	//{ Header 2: Summary Copy
	let header2 = []
	header2.push({
		orderNo: 'Order Number',
		center: '',
		date: 'Invoice Date',
		page: 'Page No.'
	});
	header2[0]['orderNo'] = {content: 'Order Number', styles: {fillColor: STYLE_HEADER_BGCOLOR, textColor: 255, fontStyle: 'bold'}};
	header2[0]['date']    = {content: 'Invoice Date', styles: {fillColor: STYLE_HEADER_BGCOLOR, textColor: 255, fontStyle: 'bold'}};
	header2[0]['page']    = {content: 'Page No.', styles: {fillColor: STYLE_HEADER_BGCOLOR, textColor: 255, fontStyle: 'bold'}};
	header2[0]['center']  = {
		rowSpan : 2,
		content : 'Summary Copy',
		styles  : {valign: 'middle', halign: 'center', fontStyle: 'bold', fillColor: 255}
	};

	header2.push({
		orderNo : Invoice.InvoiceNumber,
		center  : '',
		date    : Invoice.Date.toDate().toDateString(),
		page    : 1
	});
	//}
	
	//{ Header 3: Bill To
	const header3_head = [{billTo: 'Bill To', shipTo: 'Ship To'}];

	const BillToData = `
${Invoice.Company.BillTo.Name.First} ${Invoice.Company.BillTo.Name.Last}
${Invoice.Company.BillTo.Address}
${Invoice.Company.BillTo.City} ${Invoice.Company.BillTo.ProvinceState}
${Invoice.Company.BillTo.Country} ${Invoice.Company.BillTo.PostalCode}
	`

	const ShipToData = `
${Invoice.Company.BillTo.Name.First} ${Invoice.Company.BillTo.Name.Last}
${Invoice.Company.BillTo.Address}
${Invoice.Company.BillTo.City} ${Invoice.Company.BillTo.ProvinceState}
${Invoice.Company.BillTo.Country} ${Invoice.Company.BillTo.PostalCode}
	`

	const header3 = [{billTo: BillToData, shipTo: ShipToData}];
	//}

	//Header 4: Cart
	let STYLE_CELL_DIM_BLANKINGHEIGHT = 200;
	let header4 = [];

	const header4_head = [{
		qty   : 'QTY',
		bo    : 'BO',
		ship  : 'SHIP',
		sku   : 'SKU',
		desc  : 'DESCRIPTION',
		unitP : 'PRICE',
		total : 'TOTAL'
	}];

	Invoice.Cart.forEach(CartItem => {
		header4.push({
			qty: CartItem.Quantity,
			bo: CartItem.BackOrder,
			ship: CartItem.Shipped,
			sku: CartItem.InventoryID,
			desc: CartItem.Title,
			unitP: PriceString(CartItem.UnitPrice),
			
			total: PriceString(CartItem.UnitPrice * CartItem.Quantity),
		})
	})
	console.log('Header4', header4);
	//let style_col_widths = {qty: 10, bo: 25, ship: 35, sku: 75, unitP: 50, total: 50};
	
	//Header 4: Blanking Header
	let pageDraw = {didDrawPage: function(data) {
		header2[1].page = data.pageNumber;
		makeTable(doc, data.settings.margin.top, header1, {theme: 'striped'}, {headStyles: {fillColor: 255}}, {bodyStyles: {fillColor: 255, halign: 'center'}});
		makeTable(doc, doc.lastAutoTable.finalY, header2, {theme: 'striped'}, {bodyStyles: {valign: 'middle', halign: 'center'}});
		makeTable(doc, doc.lastAutoTable.finalY, header3, {head: header3_head}, {theme: 'striped'}, {bodyStyles: {valign: 'middle'}}, STYLE_THEME_STRIPED_ALT);
	}};
	

	
	//{ Footer 1: Checkout data

	const TotalQuantity = Invoice.Cart.reduce((acc, idx) => acc += idx.Quantity, 0);
	const TotalItems = Invoice.Cart.reduce(acc => ++acc, 0);
	const SubTotal = PriceFloat(Invoice.Cart.reduce((acc, idx) => acc += (idx.Quantity * idx.UnitPrice), 0));
	const InvoiceTaxID = Invoice.Company.Tax.TaxID;
	const InvoiceTaxRate = TaxRate[InvoiceTaxID];
	const InvoiceNetTaxRate = parseFloat(Object.keys(InvoiceTaxRate).reduce((acc, idx) => acc += InvoiceTaxRate[idx], 0));

	let footer_obj = [
		{
			Text: 'Total Quantity',
			val: TotalQuantity,
		},
		{
			Text: 'Total Item',
			val: TotalItems,
		},
		{
			Text: 'Sub Total',
			val: PriceString(SubTotal),
		},
		{
			Text: 'Shipping & Handling',
			val: PriceString(Invoice.ShippingHandling),
		},
		{
			Text: 'Discount ($)',
			val: PriceString(Invoice.Discount),
		},
		...Object.keys(InvoiceTaxRate).map(tax => (
			{
				Text: `${tax} (${Percent(InvoiceTaxRate[tax])} %)`,
				val: PriceString(PriceFloat(InvoiceTaxRate[tax]) * SubTotal)
			}
		)),
		{
			Text: 'NET Total',
			val: PriceString((SubTotal + Invoice.ShippingHandling - Invoice.Discount) * (1 + InvoiceNetTaxRate)),
		},
	]

	console.log('footer_ob', footer_obj);


	let footer1 = []

	footer_obj.map((footer_data, i) => {
		footer1.push({label: '', value: ''});
		footer1[i]['label'] = {content: footer_data.Text, styles: {halign: 'right', fillColor: STYLE_HEADER_BGCOLOR, textColor: 255, fontStyle: 'bold', fontSize: STYLE_FONT_DIM_CHECKOUT}};
		footer1[i]['value'] = {content: footer_data.val, styles: {halign: 'right', fontSize: STYLE_FONT_DIM_CHECKOUT, fontStlye: 'bold'}};
	})


	//{ Make Tables 1-3
	makeTable(doc, doc.lastAutoTable.finalY, header1, {theme: 'striped'});
	makeTable(doc, doc.lastAutoTable.finalY, header2, {theme: 'striped'}, {bodyStyles: {valign: 'middle', halign: 'center'}});
	makeTable(doc, doc.lastAutoTable.finalY, header3, {head: header3_head}, {theme: 'striped'}, {bodyStyles: {valign: 'middle'}}, STYLE_THEME_STRIPED_ALT);
	//}

	//{ Header 4: Blank header
	STYLE_CELL_DIM_BLANKINGHEIGHT = doc.lastAutoTable.finalY - doc.lastAutoTable.headHeight + FLOAT_HEADER_PADDING;
	const STYLE_HEADER_BLANKING = {headStyles: {rowHeight: STYLE_CELL_DIM_BLANKINGHEIGHT, valign: 'bottom'}};
	//}

	//{ Make Table 4 + Footer
	makeTable(doc, 40, header4, {head: header4_head}, {theme: 'striped'}, STYLE_THEME_STRIPED, STYLE_THEME_STRIPED_ALT, pageDraw, STYLE_HEADER_BLANKING);
	makeTable(doc, doc.lastAutoTable.finalY, footer1);
	//}
	
	//{Disclaimer 
	doc.setFontSize(STYLE_FONT_DIM_DISCLAIMER);
	doc.setFontStyle(STYLE_FONT_FONTSTYLE_DISCLAIMER);
	//Make Disclaimer
	doc.text(TXT_DISCLAIMER, 40, doc.lastAutoTable.finalY + 10);
	//}

	doc.save(`Invoice ${Invoice.InvoiceNumber} (${Invoice.Company.Company}).pdf`);
}