import React     from 'react'     ;

import   AppBar                   from '@material-ui/core/AppBar'      ;
import   CssBaseline              from '@material-ui/core/CssBaseline' ;
import   Divider                  from '@material-ui/core/Divider'     ;
import   Drawer                   from '@material-ui/core/Drawer'      ;
import   Hidden                   from '@material-ui/core/Hidden'      ;
import   IconButton               from '@material-ui/core/IconButton'  ;
import   Toolbar                  from '@material-ui/core/Toolbar'     ;
import   Typography               from '@material-ui/core/Typography'  ;
import { makeStyles  , useTheme } from '@material-ui/core/styles'      ;

import MenuIcon from '@material-ui/icons/Menu';

import   List           from '@material-ui/core/List'         ;
import   ListItem       from '@material-ui/core/ListItem'     ;
import   ListItemIcon   from '@material-ui/core/ListItemIcon' ;
import   ListItemText   from '@material-ui/core/ListItemText' ;
import   Collapse       from '@material-ui/core/Collapse'     ;

import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import routes from 'routes';
import { blue } from '@material-ui/core/colors';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display : 'flex'
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width      : drawerWidth,
			flexShrink : 0
		}
	},
	appBar: {
		marginLeft                   : drawerWidth,
		[theme.breakpoints.up('sm')] : {
			width : `calc(100% - ${drawerWidth}px)`
		}
	},
	menuButton: {
		marginRight                  : theme.spacing(2),
		[theme.breakpoints.up('sm')] : {
			display : 'none'
		}
	},
	toolbar     : theme.mixins.toolbar,
	drawerPaper : {
		width : drawerWidth
	},
	content: {
		flexGrow : 1,
		padding  : theme.spacing(3)
	},
	nestedList: {
		textAlign: 'left'
	},

	navroot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
	},
	selected: {
		backgroundColor: 'lightgrey',
		fontWeight: 900,
		color: 'black',
		boxShadow: '5px 5px 10px #9E9E9E',
		position: 'relative',
		transform: 'translate(-5px,-15px)',
		transition: 'all 300ms',
	},
	NavLink: {
		textDecorationLine: 'none',
		color: theme.palette.primary
	}
}));

export default function NewIndex(props) {
	const { container }               = props;

	const classes                     = useStyles();
	const theme                       = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle          = () => setMobileOpen(!mobileOpen);

	const drawer = (
		<div>
			<div className = {classes.toolbar} />
			<NavItems />	
		</div>
	);

	const drawernav =
		<nav 
			className  = {classes.drawer}
			aria-label = 'Navigation Side Panel' >
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Hidden smUp implementation='css'>
				<Drawer
					container  = {container}
					variant    = 'temporary'
					anchor     = {theme.direction === 'rtl' ? 'right' : 'left'}
					open       = {mobileOpen}
					onClose    = {handleDrawerToggle}
					classes    = {{ paper: classes.drawerPaper }}
					ModalProps = {{ keepMounted: true }} >                         // Better open performance on mobile.
					{drawer}
				</Drawer>
			</Hidden>
			<Hidden xsDown implementation='css'>
				<Drawer
					classes = {{ paper: classes.drawerPaper }}
					variant = 'permanent'
					open >
					{drawer}
				</Drawer>
			</Hidden>
		</nav>
	;

	const appbar = (
		<AppBar 
			position = 'fixed'
			className = {classes.appBar} >
			<Toolbar>
				<IconButton
					color      = 'inherit'
					aria-label = 'Open drawer'
					edge       = 'start'
					onClick    = {handleDrawerToggle}
					className  = {classes.menuButton} >
					<MenuIcon />
				</IconButton>
				<Typography variant = 'h6' noWrap>
					{
						routes.map((route, index) => (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								component={route.toolbar} />
						))
					}
				</Typography>
			</Toolbar>
		</AppBar>
	);

	const body = routes.map((route, idx) => (
		<div>
			<main
				className = {classes.content}
				style     = {{ textAlign: 'left' }} >
				<div className = {classes.toolbar}/>
				<Route
					key       = {idx}
					path      = {route.path}
					exact     = {route.exact}
					component = {route.component}
				/>
			</main>
		</div>
	));

	return (
		<div className = {classes.root}>
			<Router>
				<CssBaseline />
				{drawernav}
				{appbar}
				<main
					className = {classes.content}
					style     = {{ textAlign: 'left' }} >
					<div className = {classes.toolbar} />
					{
						routes.map((route, idx) => (
							<Route 
								key = {`${route}_${idx}`}
								path = {route.path}
								exact = {route.exact}
								component = {route.component}
							/>
						))

					}
				</main>
			</Router>
		</div>
		
	);
}

function NavItems() {
	const classes               = useStyles();
	const [Current, SetCurrent] = React.useState('InvoicesMake');

    return (
        <List
			component       = 'nav'
			aria-labelledby = 'nested-list-subheader'
			className       = {classes.navroot} >
			{
				routes
				.filter(route => route.icon)
				.map(route => (
					<Link to={route.path} className={classes.NavLink}>
						<ListItem
							button
							onClick = { () => SetCurrent(route.path)}
							color     = 'primary' >
								<ListItemIcon>{route.icon()}</ListItemIcon>
								<ListItemText 
									primary = {route.name}
									style = {{ fontWeight: 900 }} />
							</ListItem>
					</Link>
				))
			}
		</List>
    );
}