import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import { Fab } from '@material-ui/core';

const styles = {
	fab: {
		position: 'fixed',
		bottom: '1.5rem',
		right: '1.5rem',
	},

};

const Add_FAB = props => {
	const { classes } = props;
	return <Fab color='primary' className={classes.fab} {...props}><Add/></Fab>
}

Add_FAB.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Add_FAB);