import { makeStyles } from "@material-ui/styles";

const ComponentStyle = makeStyles(theme =>  ({
	TextField: {
		margin: '1rem',
	},

	FieldRow: {
		display: 'flex'
	},

	

}))

export default ComponentStyle;