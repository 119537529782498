import React, { Component } from 'react'
import Invoice_State from './state';

import NewInvoice from './NewInvoice';

import {Link} from 'react-router-dom';

import Add_FAB from 'common/inputs/Add_FAB';
import MaterialTable from 'material-table';

import tableIcons from 'common/Material-Table_Icons';
import { Save, Delete, Edit, Visibility, Add, Description } from '@material-ui/icons';
import { Invoice$, Company$, TaxRate$, Inventory$ } from 'utilities/Streams';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { firestore } from 'utilities/Firebase';
import { IconButton, ClickAwayListener, MenuList, MenuItem, CircularProgress } from '@material-ui/core';
import { DateOptions } from 'common/Constants';
import MakePDF from './MakePDF';


let InvoiceListSub, CompanyListSub, TaxRateSub, InventoryListSub;

export default class Invoices extends Component {
	
	state = {
		LoadingInvoice: true,
		LoadingCompany: true,
		LoadingTaxRate: true,
		LoadingInventory: true,
		InvoiceList: {},
		CompanyList: {},
		TaxRate: {},
		InventoryList: {},
		BackOrderList: {},
		
		

		anchorEl: null,
	};
	
	componentDidMount() {
		InvoiceListSub = Invoice$.subscribe(res => { console.log('Invoice Res', res); this.setState({InvoiceList: res, LoadingInvoice: false});});
		CompanyListSub = Company$.subscribe(res => { console.log('Company Res', res); this.setState({CompanyList: res, LoadingCompany: false});});
		TaxRateSub = TaxRate$.subscribe(res => { console.log('Tax Res', res); this.setState({TaxRate: res, LoadingTaxRate: false});});
		InventoryListSub = Inventory$.subscribe(res => { console.log('Inventory Res', res); this.setState({InventoryList: res, LoadingInventory: false});});
	}

	componentWillUnmount() {
		InvoiceListSub.unsubscribe();
		CompanyListSub.unsubscribe();
		TaxRateSub.unsubscribe();
	}

	

	render() {
		const handleFABClick = evt => this.state.anchorEl ? this.setState({anchorEl: null}) : this.setState({anchorEl: evt.currentTarget});
		const handleClose = () => this.setState({anchorEl: null});
		
		//Turn Popper to menu

		
		console.log('Match',this.match);
		console.log('Props',this.props);

		if (this.state.LoadingInvoice || this.state.LoadingCompany || this.state.LoadingTaxRate || this.state.LoadingInventory) return (<h1>Loading... <CircularProgress /></h1>)
		else {
			console.log('Invoice List', this.state.InvoiceList)
			console.log('Company List', this.state.CompanyList)
			console.log('State', this.state);
			this.state.InvoiceList.map(({InvoiceID}) => {
				let InvoiceBackOrder = this.state.BackOrderList[InvoiceID] || [];

				firestore.collection('Invoice').doc(InvoiceID).collection('BackOrder').get().then(({docs}) => {
					if (docs.length > 0) {
						if (!InvoiceBackOrder.includes(docs[0].id)) {
							InvoiceBackOrder.push(docs[0].id);
							this.setState({...this.state.BackOrderList, BackOrderList: {[InvoiceID]: InvoiceBackOrder}});
						}
					}
				})
			})

			const TableData = this.state.InvoiceList.map(({InvoiceNumber, CompanyID, BackOrdered, Cancelled, Finished, InvoiceID, Paid, PurchaseOrderID, Date, Cart, ShippingHandling, Discount}) => ({
				Company: (this.state.CompanyList.find(Company => Company.CompanyID === CompanyID) || {}),
				Status: { BackOrdered, Cancelled, Finished, Paid },
				InvoiceNumber, InvoiceID, PurchaseOrderID, Date, Cart,

				ShippingHandling,
				Discount,
			}))

			return (
				<div>
					{/* {ComponentViews[this.state.CurrentView]} */}
					{/* Edit View Print */}
					<MaterialTable
						icons={tableIcons}
						title='Invoices'
						data={TableData}
						columns={[
							/*{ title: 'Name', field: 'name' },
							{ title: 'Surname', field: 'surname' },
							{
								title: 'Birth Year',
								field: 'birthYear',
								type: 'numeric'
							},
							{
								title: 'Birth Place',
								field: 'birthCity',
								lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' }
							}*/
							{ title: 'Invoice Number', field: 'InvoiceNumber' },
							{ title: 'Date', field: 'Date', render: ({ Date }) => (
									<Typography variant='body1'>{Date.toDate().toLocaleDateString('en', DateOptions)}</Typography>
								),
								customFilterAndSearch: (filter, rowData) => {
									//xx
									const reg = RegExp(`.*(${filter}).*`, 'gi');
									console.log('r', rowData.Date.toDate().toDateString());
									console.log('z', reg.test(rowData.Date.toDate().toDateString()));
									return reg.test(rowData.Date.toDate().toDateString());
								},
								defaultSort: 'desc',
							},
							{ title: 'Company', field: 'Company', render: ({Company}) => (<Typography variant='body1'>{Company.Company}</Typography>) },
							{ title: 'Invoice ID', field: 'InvoiceID', customFilterAndSearch: (filter, rowData) => {
									const reg = RegExp(filter, 'g');
									if (reg.test(rowData.InvoiceID)) return true
									// if has sub collection invoice id of filter return true
									else return false
							}},
							{ title: 'PurchaseOrder ID', field: 'PurchaseOrderID'},
							{ title: 'Status', field: 'Status', render: ({ Status }) => {
									//xx
									//console.log('Status', Status);
								}
							},
							{ title: 'Cart Total', field: 'Cart',
								render: ({ Cart }) => (
									<Typography variant='body1'>
										{Cart.reduce(
											(acc, row) =>
												(acc += parseFloat(row.Price) * row.Quantity),
											0.0
										).toFixed(2)}
									</Typography>
								),
								customFilterAndSearch: (filter, rowData) => {
									const reg = RegExp(filter, 'g');
									return reg.test(
										rowData.Cart.reduce((acc, row) => (acc += parseFloat(row.Price)), 0.0).toFixed(2)
									);
								}
							},
							{ title: 'COGS', field: 'Cart',
								render: ({ Cart }) => (
									<Typography variant='body1'>
										{Cart.reduce(
											(acc, row) =>
												(acc += row.Quantity *  row.Cost *1),
											0.0
										).toFixed(2)}
									</Typography>
								),
							},
							{ title: 'Profit', field: 'Cart',
								render: ({ Cart }) => (
									<Typography variant='body1'>
										{Cart.reduce(
											(acc, row) =>
												(acc += row.Quantity * (row.Price - row.Cost) *1),
											0.0
										).toFixed(2)}
									</Typography>
								),
							},
						]}
						detailPanel={
							({InvoiceID}) => {return (
								<>
									{(this.state.BackOrderList[InvoiceID] || ['No Back Orders']).map(BackOrderID => <Typography variant='body1'>{BackOrderID}</Typography>)}
									<Button variant='contained' size='small' color='primary' disabled><Add/>Add Back Order Invoice</Button>
								</>
							)}
						}
						actions={[
							{
								icon: () => <Visibility />,
								tooltip: 'View Invoice',
								onClick: (event, rowData) => {
									console.log('event: ', event.target);
									console.log('rowData: ', rowData);
								}
							},
							{
								icon: () => <Description />,
								tooltip: 'Make PDF',
								onClick: (event, rowData) => {
									console.log('rowData: ', rowData);
									let PDF_Input = {
										Date: rowData.Date,
										ShippingHandling: rowData.ShippingHandling,
										Discount: rowData.Discount,
										Cart: rowData.Cart.map(({BackOrder, InventoryID, Price, Quantity, Shipped}) => ({
											Quantity, BackOrder, Shipped, InventoryID,
											Title: this.state.InventoryList.find(item => item.InventoryID === InventoryID).Title,
											UnitPrice: Price,
										})),
										Company: rowData.Company,
										InvoiceNumber: rowData.InvoiceNumber,
									}
									console.log('PDF_Input: ', PDF_Input);
									MakePDF(PDF_Input, this.state.TaxRate);
								}
							},
							{
								icon: () => <Edit />,
								tooltip: 'Edit Invoice',
								onClick: (event, rowData) => {
									console.log('event: ', event.target);
									console.log('rowData: ', rowData);
									let c = rowData.Cart.map(({Price, Cost, Quantity}) => ({
										Price, Cost, Difference: Price - Cost, Total: (Price - Cost) * Quantity
									}))
									console.log(c)
								}
							},
							{
								icon: () => <Delete />,
								tooltip: 'Delete Invoice',
								onClick: (event, rowData) => {
									console.log('event: ', event);
									console.log('rowData: ', rowData);
								}
							}
						]}
						options={{ pageSize: 20, actionsColumnIndex: -1 }} />

					<Add_FAB onClick={handleFABClick} />
					<Popper
						id='NewInvoiceOptions'
						open={Boolean(this.state.anchorEl)}
						anchorEl={this.state.anchorEl}
						transition
						placement="top-end"
						disablePortal={false}
						modifiers={{
							flip: {enabled: false,},
							preventOverflow: {
								enabled: true,
								boundariesElement: 'scrollParent',
							},
							arrow: {enabled: true,},
						}} keepMounted>
						{({ TransitionProps }) => (
							<Fade {...TransitionProps} timeout={350}>
								<Paper>
									{/* <ClickAwayListener onClickAway={handleClose}> */}
									<MenuList>
										<Link to={`/PurchaseOrders`}><MenuItem onClick={handleClose}>From Purchase Order</MenuItem></Link>
										<MenuItem onClick={handleClose}>From Scratch</MenuItem>
										<MenuItem onClick={handleClose}>Close</MenuItem>
									</MenuList>
									{/*</ClickAwayListener>*/}
								</Paper>
							</Fade>
						)}
					</Popper>
				</div>
			);
		}
	}
}
