import React, { Component } from 'react'
import { Paper, Modal, CircularProgress, Fade, MenuList, MenuItem, Popper, Button, Icon } from '@material-ui/core'
import { Inventory$ } from 'utilities/Streams';
import tableIcons from 'common/Material-Table_Icons';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/styles';
import {Link} from 'react-router-dom';
import Add_FAB from 'common/inputs/Add_FAB';
import { Close, PlusOne, Delete } from '@material-ui/icons';
import { Redirect  } from 'react-router-dom'
import { classes } from 'istanbul-lib-coverage';

let InventoryListSub;

const useStyles = makeStyles(theme => ({
	Modal: {
	},
	ModalImage: {
		position: 'absolute',
		top: '0',
		bottom: '0',
		left: '0',
		right: '0',
		margin: 'auto',
	},
	Link: {
		color: 'inherit', textDecoration: 'inherit'
	},
}));

const StyledModal = props => {
	const classes = useStyles(props);
	return (
		<Modal
			className={classes.Modal}
			open={props.ModalOpen}
			onClose={props.handleClose}>
			<img className={classes.ModalImage} src={props.ModalImgURL} height='500vw' width='auto'/>
		</Modal>
	)
}


export default class Inventory extends Component {
	state = {
		LoadingInventoryList: true,
		InventoryList: {},
		ModalOpen: false,
		ModalImgURL: '',

	}

	componentDidMount () {
		InventoryListSub = Inventory$.subscribe(res => this.setState({InventoryList: res, LoadingInventoryList: false}))
	}
	
	componentWillUnmount() {
		InventoryListSub.unsubscribe();
	}

	handleOpen = () => this.setState({ModalOpen: true})
	handleModalClose = () => this.setState({ModalOpen: false})

	handleFABClick = evt => this.state.anchorEl ? this.setState({anchorEl: null}) : this.setState({anchorEl: evt.currentTarget});
	handleFABClose = () => this.setState({anchorEl: null});
		

	
	render() {
		if (this.state.LoadingInventoryList) return (<h1>Loading... <CircularProgress /></h1>)
		else {
			const TableData = this.state.InventoryList.map(({Metadata: {ImgURL}, InventoryID, Title, Performer, Internal: {Stock}, Barcode}) => ({
				Cover: ImgURL[Object.keys(ImgURL)[0]] || '',
				InventoryID,
				Title,
				Performer,
				Stock,
				Barcode,
			}))

			return (
				<Paper>
					<MaterialTable
						icons={tableIcons}
						title='Inventory'
						data={TableData}
						columns={[
							{ title: 'Cover', field: 'PurchaseOrderID', render: ({Cover}) => (
								<img src={Cover} height='50' width='auto' onClick={() => this.setState({ModalOpen: true, ModalImgURL: Cover})}/>
							)},
							{ title: 'ID', field: 'InventoryID' },
							{ title: 'Title', field: 'Title' },
							{ title: 'Performer', field: 'Performer' },
							{ title: 'Stock', field: 'Stock'},
							{ title: 'Barcode', field: 'Barcode' },
						]}
						actions={[
							{
								icon: () => <PlusOne />,
								tooltip: 'Add Stock',
								onClick: (event, rowData) => {
									this.props.history.push(`/Inventory/AddStock/${rowData.InventoryID}`)	
								}
							},
							{
								icon: () => <Delete />,
								tooltip: 'Delete Item',
								onClick: (event, rowData) => {
									console.log('event: ', event);
									console.log('rowData: ', rowData);
								}
							}
						]}
						options={{ actionsColumnIndex: -1, pageSize: 20 }} />
					<StyledModal
						ModalOpen={this.state.ModalOpen}
						handleClose={this.handleModalClose}
						ModalImgURL={this.state.ModalImgURL}/>

					<Add_FAB onClick={this.handleFABClick} />
					<Popper
						id='NewInvoiceOptions'
						open={Boolean(this.state.anchorEl)}
						anchorEl={this.state.anchorEl}
						transition
						placement="top-end"
						disablePortal={false}
						modifiers={{
							flip: {enabled: false},
							preventOverflow: {
								enabled: true,
								boundariesElement: 'scrollParent'
							},
							arrow: {enabled: true},
						}} keepMounted>
						{({ TransitionProps }) => (
							<Fade {...TransitionProps} timeout={350}>
								<Paper>
									{/* <ClickAwayListener onClickAway={handleClose}> */}
									<MenuList>
										
										<MenuItem component={Link} to={'/Inventory/New'} onClick={this.handleFABClose}>
											New Item
										</MenuItem>
										<MenuItem component={Link} to={'/Inventory/AddStock'} onClick={this.handleFABClose}>
											Add Stock
										</MenuItem>
										<MenuItem onClick={this.handleFABClose}>
										Close
										</MenuItem>
									</MenuList>
									{/*</ClickAwayListener>*/}
								</Paper>
							</Fade>
						)}
					</Popper>

				</Paper>
			)
		}
	}
}