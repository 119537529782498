import { collectionData, docData } from './Firebase';

/* Main Collections */
import {
	BackOrderCollectionRef    ,
	CompanyCollectionRef      ,
	CoverCollectionRef        ,
	GenerateCollectionRef     ,
	InventoryCollectionRef    ,
	InvoiceCollectionRef      ,
	LinkCollectionRef         ,
	PurchaseOrderCollectionRef,
	SettingCollectionRef,
	TaxRateRef
} from './References';

/* Links Collection Documents */
import {
	InvoiceID_PurchaseOrderRef  ,
	InvoiceNumber_InvoiceIDRef  ,
	InvoiceNumber_InvoicePathRef,
} from './References'

/* Settings Collection Documents */
import {
	SettingsDefaultRef,
	SettingsTriggerRef
} from './References';

const BackOrder$     = collectionData(BackOrderCollectionRef    , 'BackOrderID'    );
const Company$       = collectionData(CompanyCollectionRef      , 'CompanyID'      );
const Cover$         = collectionData(CoverCollectionRef        , 'CoverID'        );
const Generate$      = collectionData(GenerateCollectionRef     , 'GenerateID'     );
const Inventory$     = collectionData(InventoryCollectionRef    , 'InventoryID'    );
const Invoice$       = collectionData(InvoiceCollectionRef      , 'InvoiceID'      );
const Link$          = collectionData(LinkCollectionRef         , 'LinkID'         );
const PurchaseOrder$ = collectionData(PurchaseOrderCollectionRef, 'PurchaseOrderID');
const Setting$       = collectionData(SettingCollectionRef      , 'SettingID'      );

const InvoiceID_PurchaseOrder$   = docData(InvoiceID_PurchaseOrderRef  );
const InvoiceNumber_InvoiceID$   = docData(InvoiceNumber_InvoiceIDRef  );
const InvoiceNumber_InvoicePath$ = docData(InvoiceNumber_InvoicePathRef);

const SettingsDefault$ = docData(SettingsDefaultRef);
const SettingsTrigger$ = docData(SettingsTriggerRef);
const TaxRate$ = docData(TaxRateRef);


export {
	BackOrder$    ,
	Company$      ,
	Cover$        ,
	Generate$     ,
	Inventory$    ,
	Invoice$      ,
	Link$         ,
	PurchaseOrder$,
	Setting$
};

export {
	InvoiceID_PurchaseOrder$  ,
	InvoiceNumber_InvoiceID$  ,
	InvoiceNumber_InvoicePath$,
	TaxRate$,
	SettingsDefault$,
	SettingsTrigger$,
}
