import React, { Component } from 'react'
import { PurchaseOrder$ } from 'utilities/Pipes';
import { InvoiceID_PurchaseOrder$ } from 'utilities/Streams';
import { Paper, Typography, Chip, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import tableIcons from 'common/Material-Table_Icons';
import MaterialTable from 'material-table';
import { Visibility, Edit, Delete, NoteAdd } from '@material-ui/icons';
import { DateOptions } from 'common/Constants';
import { makeStyles } from '@material-ui/styles';
import {Link} from 'react-router-dom';

let PurchaseOrderSub, InvoiceSub;

const useStyles = makeStyles({
	Chip: props => ({
		backgroundColor: props.color,
	}),
});

const StyledChip = props => {
	const classes = useStyles(props);
	return <Chip className={classes.Chip} {...props} />
}

class PurchaseOrders extends Component {

	state = {
		LoadingPurchaseOrder: true,
		LoadingInvoiceID_PurchaseOrder: true,
		PurchaseOrder: {},
		InvoiceID_PurchaseOrder: {},
	}

	componentWillMount() {
		PurchaseOrderSub = PurchaseOrder$.subscribe(res => this.setState({PurchaseOrder: res, LoadingPurchaseOrder: false}));
		InvoiceSub = InvoiceID_PurchaseOrder$.subscribe(res => this.setState({InvoiceID_PurchaseOrder: res, LoadingInvoiceID_PurchaseOrder: false}));
	}

	componentWillUnmount() {
		PurchaseOrderSub.unsubscribe();
		InvoiceSub.unsubscribe();
	}
	
	render () {
		if (this.state.LoadingPurchaseOrder || this.state.LoadingInvoiceID_PurchaseOrder) return (<h1>Loading... <CircularProgress /></h1>)
		else {
			{console.log('Purchase Order Data: ', this.state.PurchaseOrder)}
			{console.log('InvoiceID_PurchaseOrder Data: ', this.state.InvoiceID_PurchaseOrder)}

			const TableData = this.state.PurchaseOrder.map(({PurchaseOrderID, Date, Company, Cart, Cancelled, Finished}) => ({
				PurchaseOrderID,
				Date,
				Company: Company.Company,
				Cart,
				Status: {Cancelled, Finished}
			}))

			return (
				<Paper>
					<MaterialTable
						icons={tableIcons}
						title='Purchase Orders'
						data={TableData}
						columns={[
							{
								title: 'Date',
								field: 'Date',
								render: ({ Date }) => (
									<Typography variant='body1'>
										{Date.toDate().toLocaleDateString('en', DateOptions)}
									</Typography>
								),
								defaultSort: 'desc',
							},
							{ title: 'PurchaseOrder ID', field: 'PurchaseOrderID'},
							{ title: 'Company', field: 'Company' },
							{
								title: 'Status',
								field: 'Status',
								render: ({ Status }) => {
									if (Status.Cancelled) return <Chip label='Cancelled' size='small'/>
									if (Status.Finished) return <Chip label='Finished' size='small' color='primary'/>
									else return <Chip label='Active' size='small' color='secondary'/>
								}
							},
							{
								title: 'Invoice',
								field: 'PurchaseOrderID',
								render: ({ PurchaseOrderID }) => (
									Object.keys(this.state.InvoiceID_PurchaseOrder).find(InvoiceID => this.state.InvoiceID_PurchaseOrder[InvoiceID] === PurchaseOrderID) ?
									(<Link to={`/Invoices/View/${PurchaseOrderID}`}>{PurchaseOrderID}</Link>)
									:
									(<Tooltip title='Make Invoice' placement='top'>
										<Link to={`/Invoices/New/${PurchaseOrderID}`}><IconButton size='small'><NoteAdd/></IconButton></Link>
									</Tooltip>)
								)
							},
							
						]}
						detailPanel={
							({ Cart }) => {return (
								<Table size='small'>
									<TableHead>
										<TableRow>
											<TableCell>Cover</TableCell>
											<TableCell>ID</TableCell>
											<TableCell>Quantity</TableCell>
											<TableCell>Title</TableCell>
											<TableCell>Stock</TableCell>
											<TableCell>Location</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{Cart.map(item => {
										console.log(item);
										console.log(item.Metadata.ImgURL[Object.keys(item.Metadata.ImgURL)[0]])
									})}
										{
											Cart.map(({Metadata: {ImgURL}, InventoryID, Title, Internal: {Stock, StorageLocation}, Quantity}) => (
												<TableRow>
													<TableCell><img src={ImgURL[Object.keys(ImgURL)[0]]} height='50' width='auto'/></TableCell>
													<TableCell>{InventoryID}</TableCell>
													<TableCell>{Quantity}</TableCell>
													<TableCell>{Title}</TableCell>
													<TableCell>{Stock}</TableCell>
													<TableCell>{StorageLocation}</TableCell>
												</TableRow>
											))
										}
									</TableBody>
								</Table>
							)}
						}
						actions={[
							{
								icon: () => <Visibility />,
								tooltip: 'View Purchase Order',
								onClick: (event, rowData) => {
									console.log('event: ', event.target);
									console.log('rowData: ', rowData);
								}
							},
							{
								icon: () => <Delete />,
								tooltip: 'Delete Purchase Order',
								onClick: (event, rowData) => {
									console.log('event: ', event);
									console.log('rowData: ', rowData);
								}
							}
						]}
						options={{ actionsColumnIndex: -1, pageSize: 20 }}
					/>
				</Paper>
			)
		}
	}
}

export default PurchaseOrders

/* "E2g5CNklvUWYmTj9mbFt"
"j2fxk1vc658VnPFilCK7" */