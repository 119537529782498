const ComponentStyle = theme => ({
	Container: {
		width: '95%',
		maxWidth: 'none',
	},
	SearchList: {
		backgroundColor: theme.palette.background.paper
	}
});

export default ComponentStyle;
