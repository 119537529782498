import React, { useState, useEffect } from 'react'
import { Inventory$ } from 'utilities/Streams';
import { Container, Card, CardContent, CircularProgress, TextField, List, ListItem, ListItemText, Avatar, ListItemAvatar, ListItemSecondaryAction, Button, Grid, Typography, Divider, InputAdornment, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ComponentStyle from './AddStockStyle';
import firebase, {firestore} from 'utilities/Firebase';

import Fuse from 'fuse.js';
import { Album, ChevronRight, Search } from '@material-ui/icons';
import CustomSnackBar from 'common/feedback/CustomSnackbar';

const useStyles = makeStyles(ComponentStyle);

export default function AddStock({match}) {
	const classes = useStyles();

	const InventoryID = match.params.InventoryID || undefined;

	const [LoadingInventory, setLoadingInventory] = useState(true);
	const [InventoryList, setInventoryList]       = useState(null);

	const [SearchField, setSearchField]             = useState('');
	const [SearchResult, setSearchResult]           = useState([]);
	const [SelectedInventory, setSelectedInventory] = useState(undefined);

	const [NewAmount, setNewAmount] = useState(0);
	const [NewCost, setNewCost]     = useState(0);

	const [SnackbarState, setSnackbarState] = useState({open: false, message: '', variant: 'error'});

	let fuse;

	useEffect(() => {
		let InventorySub = Inventory$.subscribe(res => {
			setInventoryList(res);
			setLoadingInventory(false);
			if (match) {
				let URLMatch = res.find(item => item.InventoryID === match.params.InventoryID);
				setSearchField(match.params.InventoryID);
				
				setSelectedInventory(URLMatch);
			}
		});
		return () => {
			InventorySub.unsubscribe();
		};
	}, [])

	const handleSearchChange = evt => {
		setSearchField(evt.target.value);
		setSearchResult(fuse.search(evt.target.value).slice(0, 5));
	};

	const handleSearchEnter = ({key}) => {
		if (key === 'Enter') {
			let ExactMatch = SearchResult.find(res => res.score === 0);
			if (ExactMatch) setSelectedInventory(ExactMatch.item)
		}
	}

	const handleListSelection = evt => {
		let SelectedMatch = SearchResult.find(({item}) => item.InventoryID === evt.currentTarget.dataset.inventoryid);
		setSelectedInventory(SelectedMatch.item)
	}

	const handleNewAmount = evt => setNewAmount(parseInt  (evt.target.value));
	const handleNewCost   = evt => setNewCost  (parseFloat(evt.target.value));

	const handleConfirmChange = async () => {
		try {
			await firestore.collection('Inventory').doc(SelectedInventory.InventoryID).update({
				"Internal.Stock" : parseInt(CalculateNewAmount()),
				"Internal.Cost"  : parseFloat(CalculateNewCost()),
				"Internal.InventoryDate" : firebase.firestore.FieldValue.serverTimestamp(),
			});
			setSnackbarState({open: true, message: 'Update Success', variant: 'success'});
		} 
		catch (err) { console.log('Error', err); setSnackbarState({open: true, message: 'Update Failed', variant: 'error'}); }
	}

	const CalculateNewAmount = () => parseInt(SelectedInventory.Internal.Stock + NewAmount);
	const CalculateNewCost   = () => parseFloat(((SelectedInventory.Internal.Stock * SelectedInventory.Internal.Cost) + (NewAmount * NewCost))/CalculateNewAmount()).toFixed(2);

	const SearchResultList = () => (
		SearchResult.map(({item:{InventoryID, Title}}) => (
			<ListItem>
				<ListItemAvatar>
					<Avatar>
						<Album />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary = {InventoryID}
					secondary = {Title} />
				<ListItemSecondaryAction>
					<Button
						variant="contained"
						color="primary"
						data-inventoryid={InventoryID}
						onClick={handleListSelection} >
						<ChevronRight />
					</Button>
				</ListItemSecondaryAction>
			</ListItem>
		))
	)
	
	const StockField = props => (
		<TextField
			margin='normal'
			variant='filled'
			{...props}
		/>
	)

	const fuseOptions = {
		keys         : ['InventoryID', 'Title', 'Barcode'],
		shouldSort   : true,
		includeScore : true,
	}

	if (LoadingInventory) return (<h1>Loading... <CircularProgress /></h1>)
	else {
		fuse = new Fuse(InventoryList, fuseOptions);

		return (
			<Container className={classes.Container}>
				<Grid container direction='row' spacing={1}>
					<Grid item xs={12} sm={6}>
						<Card className={classes.CardPanel}>
							<CardContent>
								<Typography variant='h5'>Search</Typography>
								<Divider />
								<TextField
									label='Search Inventory'
									margin='normal'
									variant='filled'
									value={SearchField}
									onChange={handleSearchChange}
									onKeyPress={handleSearchEnter}
									InputProps={{startAdornment: <InputAdornment position="start"><Search/></InputAdornment>}}/>

								<div className={classes.SearchList}>
									<List dense={false}>
										{SearchResultList()}
									</List>
								</div>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Card className={classes.CardPanel}>
							<CardContent>
								<Typography variant='h5'>Add Stock Amount</Typography>
								<Divider />
								<Grid item xs={12}>
									<StockField
										label='Inventory ID'
										value={SelectedInventory ? SelectedInventory.InventoryID : ''}
										disabled />
									<StockField
										label='Title'
										value={SelectedInventory ? SelectedInventory.Title : ''}
										disabled />
								</Grid>
								<Grid item xs={12}>
									<StockField
										label='Current Stock'
										value={SelectedInventory ? SelectedInventory.Internal.Stock : 0}
										type='number'
										disabled />
									<StockField
										label='Current Cost'
										value={SelectedInventory ? SelectedInventory.Internal.Cost : 0.00}
										InputProps={{ startAdornment: <InputAdornment position='start'>$ </InputAdornment> }}
										type='number'
										disabled />
								</Grid>
								<Grid item xs={12}>
									<TextField
										label='New Inventory Amount'
										margin='normal'
										variant='filled'
										type='number'
										value={NewAmount}
										onChange={handleNewAmount} />
									<TextField
										label='New Inventory Cost'
										margin='normal'
										variant='filled'
										type='number' 
										value={NewCost}
										onChange={handleNewCost} />
								</Grid>
								<Grid item xs={12}>
									<StockField
										label='New Stock'
										value={SelectedInventory ? CalculateNewAmount() : 0}
										type='number'
										disabled />
									<StockField
										label='New Cost'
										value={SelectedInventory ? CalculateNewCost() : 0.00}
										type='number'
										disabled />
								</Grid>
								<Grid item xs={12}>
									<Button
										color='primary'
										variant='contained'
										onClick={handleConfirmChange}>Confirm</Button>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open={SnackbarState.open}
					autoHideDuration={6000} >
					<CustomSnackBar
						variant={SnackbarState.variant}
						message={SnackbarState.message} />
				</Snackbar>
			</Container>
		)
	}
}