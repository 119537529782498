import React from "react";

import Home           from 'routes/home/index'      ;
import Data           from "routes/data"            ;

/* Inventory */
import Inventory      from "routes/inventory/idx"       ;
import AddStock from 'routes/inventory/AddStock';
import NewInventory from "routes/inventory/NewInventory";

/* Customers */
import Customers      from "routes/customers"       ;
import {CustomerDetails} from "routes/customers/detailed";

/* Back Orders */
import BackOrders     from 'routes/backOrders/index';

/* Purchase Orders */
/* import PurchaseOrders from "routes/purchaseOrders"  ; */
import PurchaseOrders from 'routes/purchaseOrders/idx';

/* Invoices  */
import Invoices from 'routes/invoice';
import Details from 'routes/invoice';
import NewInvoice from "routes/invoice/NewInvoice";
/* import Invoices       from "routes/invoice"         ; */


import Discog         from "routes/discog"          ;
import Utilities      from "routes/utilities"       ;
import Settings       from "routes/settings"        ;


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Home as HomeIcon,
    BarChart,
    Person,
    RestorePage,
    Description,
    Settings as SettingsIcon,
    StarBorder,
    ExpandLess,
    ExpandMore
} from '@material-ui/icons';

import {
    faTools,
    faCompactDisc,
    faBoxOpen,
    faCartArrowDown
} from '@fortawesome/free-solid-svg-icons';


const routes = [
	{
		path    : '/',
		exact   : true,
		name    : 'Home',
		icon    : () => <HomeIcon/>,
		toolbar : () => <p>Home</p>,
		component    : Home
	},
	{
		path    : '/Data',
		name    : 'Data',
		icon    : () => <BarChart />,
		toolbar : () => <p>Data</p>,
		component    : Data
	},

	/* Inventory */
	{
		path    : '/Inventory',
		name    : 'Inventory',
		exact: true,
		icon    : () => FAIcon(faBoxOpen),
		toolbar : () => <p>Inventory</p>,
		component    : Inventory
	},
	{
		path    : '/Inventory/New',
		name    : 'New Inventory',
		exact: true,
		toolbar : () => <p>New Inventory</p>,
		component    : NewInventory
	},
	{
		path    : '/Inventory/AddStock',
		name    : 'Add Stock',
		exact: true,
		toolbar : () => <p>Add Stock</p>,
		component    : AddStock
	},
	{
		path    : '/Inventory/AddStock/:InventoryID',
		name    : 'Add Stock',
		exact: false,
		toolbar : () => <p>Add Stock</p>,
		component    : AddStock
	},
	{
		path    : '/Inventory/:InventoryID',
		name    : 'Inventory Details',
		exact: true,
		toolbar : () => <p>Inventory Details</p>,
		component    : Inventory
	},

	/* Customers */
	{
		path    : '/Customers',
		name    : 'Customers',
		icon    : () => <Person />,
		toolbar : () => <p>Customers</p>,
		exact: true,
		component    : Customers
	},
	{
		path    : '/Customers/:CompanyID',
		name    : 'Customers Detailed View',
		toolbar : () => <p>Customers Detailed View</p>,
		exact: false,
		component    : CustomerDetails
	},

	/* Back Orders */
	{
		path    : '/BackOrders',
		name    : 'Back Orders',
		icon    : () => <RestorePage />,
		toolbar : () => <p>Back Orders</p>,
		component    : BackOrders
	},
	{
		path    : '/BackOrders/:BackOrderID',
		name    : 'Back Orders Detailed View',
		toolbar : () => <p>Back Orders Detailed View</p>,
		component    : BackOrders
	},

	/* Purchase Orders */
	{
		path    : '/PurchaseOrders',
		name    : 'Purchase Orders',
		icon    : () => FAIcon(faCartArrowDown),
		toolbar : () => <p>Purchase Orders</p>,
		component    : PurchaseOrders
	},
	{
		path      : '/PurchaseOrders/:PurchaseOrderID',
		name      : 'Purchase Orders Detailed View',
		toolbar   : () => <p>Purchase Orders Detailed View</p>,
		component : PurchaseOrders
	},

	/* Invoices */
	{
		path      : '/Invoices',
		name      : 'Invoices',
		icon      : () => <Description />,
		toolbar   : () => <p>Invoices</p>,
		exact     : true,
		component : Invoices
	},
	{
		path      : '/Invoices/New',
		name      : 'New Invoice',
		toolbar   : () => <p>New Invoice</p>,
		exact     : true,
		component : Details
	},
	{
		path      : '/Invoices/New/BackOrder/:InvoiceID',
		name      : 'New Back Order Invoice',
		toolbar   : () => <p>New Back Order Invoice</p>,
		exact     : true,
		component : NewInvoice
	},
	{
		path      : '/Invoices/New/:PurchaseOrderID',
		name      : 'New Invoice from Purchase Order',
		toolbar   : () => <p>New Invoice from Purchase Order</p>,
		exact     : true,
		component : NewInvoice
	},
	{
		path      : '/Invoices/:InvoiceID',
		name      : 'Invoices Detailed',
		toolbar   : () => <p>Invoices Detailed</p>,
		exact     : true,
		component : Details
	},


	/* Discog */
	{
		path    : '/Discog',
		name    : 'Discog',
		icon    : () => FAIcon(faCompactDisc),
		toolbar : () => <p>Discog</p>,
		component    : Discog
	},

	/* Utilities */
	{
		path    : '/Utilities',
		name    : 'Utilities',
		icon    : () => FAIcon(faTools),
		toolbar : () => <p>Utilities</p>,
		component    : Utilities
	},

	/* Settings */
	{
		path    : '/Settings',
		name    : 'Settings',
		icon    : () => <SettingsIcon />,
		toolbar : () => <p>Settings</p>,
		component    : Settings
	},
];

let FAIcon = (name, size = 'lg') => <FontAwesomeIcon icon={name} size={size} fixedWidth />;


export default routes;

/* 
Switch
Ambiguous Routes
Auth Protected Routes

/
/Data
/Inventory
	/:InventoryID
/Customers
	/:CompanyID
/BackOrders
	/:BackOrderID
/PurchaseOrders
	/:PurchaseOrderID
/Invoices
	/:InvoiceID
	/New
		/Invoices/New/
		/:PurchaseOrderID
/Discog
	/Listings
	/Orders
	/Invoices
/Utilities
	/UploadCovers
/Settings
*/