import app, { firestore } from 'utilities/Firebase';

const CreateInvoice = async ({InvoiceNumber, Date, BackOrdered, Cancelled, Finished, Paid, PurchaseOrderID, CompanyID, ShippingHandling, Discount, Cart}) => {
	BackOrdered = Cart.reduce((acc, val) => {
		if (val.BackOrder > 0) acc = true;
		return acc;
	}, false) || BackOrdered;
	try {
		let InvoiceRef = await firestore.collection('Invoice').add({
			InvoiceNumber,
			Date,
	
			BackOrdered,
			Cancelled,
			Finished,
			Paid,
	
			PurchaseOrderID,
			CompanyID,
			
			Cart: Cart.map(({InventoryID, Quantity, BackOrder, Shipped, UnitPrice, Internal: {Cost}}) => 
					({ InventoryID, Cost, Quantity, BackOrder, Shipped, Price: UnitPrice })
				),
	
			ShippingHandling,
			Discount,
		});
		return InvoiceRef;
	} catch (err) { console.log('Save Invoice Error', err) }
}

const SaveInvoice = async (Invoice_State, commit = false) => {
	console.log(Invoice_State);
	try {
		//Check if invoice exists ? update : set
		let InvoiceReferenceID = await CreateInvoice(Invoice_State);

		let batch = firestore.batch();
		if (commit) {
			await UpdateStock(Invoice_State, batch);
			await IncrementInvoiceNumber(batch);
			await SetLink(InvoiceReferenceID, Invoice_State, batch);

			if (Invoice_State.Finished) { await FinishPurchaseOrder(Invoice_State, batch) }
		}

		await batch.commit();

	} catch (err) { console.log('Save Invoice Error', err) }
}

const IncrementInvoiceNumber = async (batch) => {
	let Reference = firestore.collection('Setting').doc('Default');
	let IncrementInvoice = app.firestore.FieldValue.increment(1);
	await batch.update(Reference, {'InvoiceNumber': IncrementInvoice});
}

const UpdateStock = async (Invoice_State, batch) => {
	let DecrementStock = ChangeBy => app.firestore.FieldValue.increment(-1 * ChangeBy);
	let InventoryCollection = firestore.collection('Inventory');

	let Cart = Invoice_State.Cart;
	for (let CartItem of Cart) {
		let DocRef = InventoryCollection.doc(CartItem.InventoryID);
		await batch.update(DocRef, {"Internal.Stock": DecrementStock(CartItem.Quantity)});
	}
}

const FinishPurchaseOrder = async (Invoice_State, batch) => {
	let PurchaseOrderRef = firestore.collection('PurchaseOrder').doc(Invoice_State.PurchaseOrderID);
	await batch.update(PurchaseOrderRef, {'Finished': true});
}

const SetLink = async (InvoiceRef, Invoice_State, batch) => {
	try {
		let LinkDoc = firestore.collection('Link');
		await batch.update(LinkDoc.doc('InvoiceID-PurchaseOrderID'), {[InvoiceRef.id] : Invoice_State.PurchaseOrderID})
		await batch.update(LinkDoc.doc('InvoiceNumber-InvoiceID'), {[Invoice_State.InvoiceNumber]: InvoiceRef.id})
		await batch.update(LinkDoc.doc('InvoiceNumber-InvoicePath'), {[Invoice_State.InvoiceNumber]: firestore.collection('Invoice').doc(InvoiceRef.id)})
	} catch (err) { console.log('Link Error', err) }
}


export default SaveInvoice;

/* 
[x] Finished
[ Save ]

[ Make PDF ]

Routes:
	/Invoices
	/Invoices/New
	/Invoices/New/:PurchaseOrderID
	** /Invoices/New/BackOrder/:InvoiceID
	** /Invoices/Edit/:InvoiceID
	/Invoices/View/:InvoiceID

Create Invoice {
	[Invoice/:autoID]
	If (BackOrder) [Invoice/:autoID/BackOrdered] => true
}

Update Inventory Stock {
	[Inventory/:InventoryID/Internal/Stock]
	If (BackOrder) [Inventory/:InventoryID/Flag/BackOrdered] => true
}
If (BackOrder) {
	[BackOrder/:InventoryID/InvoiceID].union(:InvoiceID) && [BackOrder/:InventoryID/Quantity] + BackOrder
}
If (!BackOrder && Finsih) {
	[Invoice/:autoID/Finished] => true
}
If (!BackOrder && PurchaseOrder) {
	[PurchaseOrder/:PurchaseOrderID/Finished] => true
}
Set Link (if Finished)
	if (purchasOrderID) InvoiceID-PurhcaseOrderID [Link/InvoiceID-PurchaseOrderID]
	InvoiceNumbe-InvoiceID [Link/InvoiceNumber-InvoiceID]
	InvoiceNumber-InvoicePath [Link/InvoiceNumber-InvoicePath]
Increment InvoiceNumber [Settings/Default/InvoiceNumber]

Invoice Flags:
	BackOrdered
	Cancelled
	Finished
	Paid
Purchase Order Flags:
	Finished
	Cancelled
Inventory Flags:
	Flags:
		BackOrdered
		Visible

*/