import {
	BackOrder$     as _BackOrder$    ,
	Company$       as _Company$      ,
	Cover$         as _Cover$        ,
	Generate$      as _Generate$     ,
	Inventory$     as _Inventory$    ,
	Invoice$       as _Invoice$      ,
	Link$          as _Link$         ,
	PurchaseOrder$ as _PurchaseOrder$,
	Setting$       as _Setting$,
	TaxRate$       as _TaxRate$,
} from './Streams';
import { switchMap, map, tap } from 'rxjs/operators';
import {combineLatest} from 'rxjs'
import { createConfigItem } from '@babel/core';

/* const PurchaseOrder$ = _PurchaseOrder$
.pipe(
	switchMap(PurchaseOrderArray =>
		combineLatest(
			...PurchaseOrderArray.map(PurchaseOrder =>
				combineLatest(
					_Company$.pipe(
						map(CompanyArray =>
							CompanyArray.filter(company => company.CompanyID === PurchaseOrder.CompanyID)[0]
						)
					),
					_Inventory$.pipe(
						map(InventoryArray => [
							...PurchaseOrder.Cart.map(CartItem => ({
								...CartItem,
								...InventoryArray.filter(inventory => inventory.InventoryID === CartItem.ID)[0]
							}))
						])
					),
					[{ PurchaseOrder }]
				)
			)
		)
	),
	map(c =>
		c.map(([Company, Cart, PurchaseOrder]) => {
			let ret = { ...PurchaseOrder };
			ret['PurchaseOrder']['Cart'] = Cart;
			ret['PurchaseOrder']['Company'] = Company;
			console.log(ret);
			return ret;
		})
	)
)
 */

const PurchaseOrder$ = _PurchaseOrder$.pipe(
	switchMap(PurchaseOrderArray =>
		combineLatest(
			...PurchaseOrderArray.map(PurchaseOrder =>
				combineLatest(
					_Company$.pipe(
						map(CompanyArray => CompanyArray.find(company => company.CompanyID === PurchaseOrder.CompanyID))
						/* map(CompanyArray => CompanyArray.filter(company => company.CompanyID === PurchaseOrder.CompanyID)[0]) */
					),

					_Inventory$.pipe(
						map(InventoryArray => [
							...PurchaseOrder.Cart.map(CartItem => (
								{
									...CartItem,
									...InventoryArray.find(inventory => inventory.InventoryID === CartItem.InventoryID)
									/* ...InventoryArray.filter(inventory => inventory.InventoryID === CartItem.InventoryID)[0] */
								}
							))
						])
					),
					[{ PurchaseOrder }]
				)
			)
		)
	),
	tap(
		res => console.log('Pipe', res)
	),
	map(c =>
		c.map(([Company, Cart, PurchaseOrder]) => {
			let ret = { ...PurchaseOrder.PurchaseOrder };
			ret['Cart'] = Cart;
			ret['Company'] = Company;
			return ret;
		})
	)
);

export { PurchaseOrder$ };
