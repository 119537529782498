import React, { useState, useEffect } from 'react'
import { Paper, Card, TextField, Chip, Typography, Checkbox, FormControlLabel, CardContent, Button, Modal, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import ComponentStyle from './styles';
import { Save } from '@material-ui/icons';
import SaveInventory from './SaveInventory';
import  { set } from 'lodash';
import { SettingsDefault$ } from 'utilities/Streams';
import firebase from 'utilities/Firebase'

const useStyles = ComponentStyle;

export default function NewInventory() {
	const classes = useStyles();

	const [InventoryItem, setInventoryItem] = useState({
		Title       : '',
		Barcode     : '',
		ID          : '',
		SKU         : '',
		Label       : '',
		Genre       : [],
		Performer   : '',
		Description : '',
		Source      : '',
		Internal: {
			Cost            : 0.00,
			InventoryDate   : firebase.firestore.FieldValue.serverTimestamp(),
			Stock           : 0,
			StorageLocation : '',
		},
		Metadata: {
			ImgURL    : {},
			SourceURL : '',
		},
		Physical: {
			Height      : 0.00,
			Length      : 0.00,
			Weight      : 0.00,
			Width       : 0.00,
			MediaAmount : 0,
			MediaType   : '',
		},
		Price: {
			DealerPrice : 0.00,
			RetailPrice : 0.00,
			SourcePrice : 0.00,
		},
		Track: [{
			Name      : '',
			Disk      : '',
			Side      : '',
			Track     : '',
			Length    : 0.00,
			Performer : {}
		}],
		Flag: {
			BackOrdered : false,
			Stale       : false,
			Visible     : false,    //Settings-Default-Visible
		},
		Trigger: {
			LowInventory: 0, //Settings-Default-LowInventory
		},
	});

	const [GenreText, setGenreText] = useState('');
	const [SettingsDefault, setSettingsDefault] = useState({});

	useEffect(() => {
		const SettingsDefaultSub = SettingsDefault$.subscribe(res => {
			setSettingsDefault(res);
			setInventoryItem({
				...InventoryItem,
				Flag: {Visible: res.Visible},
				Trigger: {LowInventory: res.LowInventory},
			})
		});		

		return () => { SettingsDefaultSub.unsubscribe() };
	}, [])

	const handleChange = evt => setInventoryItem({...InventoryItem, [evt.target.id]: evt.target.value});

	const handleNestedChange = evt => {
		let temp = InventoryItem;
		evt.target.id.split('.')
		.reduce((o,p,i) => o[p] = evt.target.id.split('.').length === ++i ? evt.target.value : o[p] || {}, temp);
		setInventoryItem(_ => ({...temp}));
	}

	const handleGenreChange = evt => setGenreText(evt.target.value);

	const handleGenreAdd = evt => {
		let GenreArray = InventoryItem.Genre;
		if (evt.key === 'Enter') {
			GenreArray.push(GenreText);
			setInventoryItem(InventoryItem => ({...InventoryItem, Genre: GenreArray}))
			setGenreText('');
		}		
	};

	const handleGenreDelete = genre => setInventoryItem({...InventoryItem, Genre: InventoryItem.Genre.filter(GenreType => GenreType !== genre)});

	const handleNestedBoolean = evt => {
		let temp = InventoryItem;
		set(temp, evt.target.id, evt.target.checked);
		setInventoryItem({...temp});
	};

	const check = () => {return true};

	const handleSave = async () => {
		//run checks
		//1 ID is clean (no '/', ' ') See sheets.
		//2 All fields not empty
		//3 use lodash set
		
		if (check()) {
			
			try {
				await SaveInventory(InventoryItem)
			} catch (err) {
				return (
				<Modal
				open={true}>
					<p>sjfiojgiodf</p>
				</Modal>
				)
				
			}
		}
	};



	return (
		<Paper className={classes.root}>
			<Card className={classes.Card}>
				<Typography variant='h5'>Basic</Typography>
				<form>
					{['ID', 'SKU', 'Title', 'Barcode'].map(item => 
						<TextField 
						id={item}
						label={item}
						className={classes.InputField}
						value={InventoryItem[item]}
						onChange={handleChange}
						variant='filled' />
					)}
				</form>
			</Card>
			<Card className={classes.Card}>
				<Typography variant='h5'>Detail</Typography>
				<form>
					{['Source', 'Label', 'Performer'].map(item => 
						<TextField 
							id={item}
							label={item}
							className={classes.InputField}
							value={InventoryItem[item]}
							onChange={handleChange}
							variant='filled' />
					)}
					<TextField
						id='Description'
						label='Description'
						className={classes.InputField}
						value={InventoryItem['Description']}
						onChange={handleChange}
						multiline
						variant='filled'
						/>
					<TextField 
						label='Genre'
						className={classes.InputField}
						value={GenreText}
						onChange={handleGenreChange}
						onKeyDown={handleGenreAdd}
						variant='filled' />
					{InventoryItem.Genre.map(row =>
						<Chip
							label={row}
							onDelete={()=> handleGenreDelete(row)}
						/>
					)}
				</form>
			</Card>
			<Card className={classes.Card}>
				<Typography variant='h5'>Internal</Typography>
				<form>
					<TextField 
						id='Internal.Cost'
						label='Cost'
						className={classes.InputField}
						value={InventoryItem.Internal.Cost || 0.00}
						onChange={handleNestedChange}
						type='number'
						variant='filled' />
					<TextField 
						id='Internal.InventoryDate'
						label='InventoryDate'
						className={classes.InputField}
						value={InventoryItem.Internal.InventoryDate}
						variant='filled'
						disabled />
					<TextField 
						id='Internal.Stock'
						label='Stock'
						className={classes.InputField}
						value={InventoryItem.Internal.Stock || 0}
						onChange={handleNestedChange}
						type='number'
						variant='filled' />
					<TextField 
						id='Internal.StorageLocation'
						label='Storage Location'
						className={classes.InputField}
						value={InventoryItem.Internal.StorageLocation}
						onChange={handleNestedChange}
						variant='filled' />
				</form>
			</Card>
			<Card className={classes.Card}>
				<Typography variant='h5'>Metadata</Typography>
				<form>
					<TextField
						id={'Metadata.SourceURL'}
						label='Source URL'
						className={classes.InputField}
						value={InventoryItem.Metadata['SourceURL']}
						onChange={handleNestedChange}
						variant='filled' />
				</form>
			</Card>
			<Card className={classes.Card}>
				<Typography variant='h5'>Physical</Typography>
				<form>
					{['Height', 'Length', 'Width', 'Weight'].map(row => 
						<TextField
							id={`Physical.${row}`}
							label={row}
							className={classes.InputField}
							value={InventoryItem.Physical[row] || 0.00}
							onChange={handleNestedChange}
							type='number'
							variant='filled' />
					)}
					<TextField
						id='Physical.MediaAmount'
						label='Disks per Item'
						className={classes.InputField}
						value={InventoryItem.Physical.MediaAmount || 0}
						onChange={handleNestedChange}
						type='number'
						variant='filled' />
					<FormControl variant='filled'>
						<InputLabel htmlFor='Physical.MediaType'>Media Type</InputLabel>
						<Select
							value={InventoryItem.Physical.MediaType}
							inputProps={{id: 'Physical.MediaType'}} >
							<MenuItem value='VINYL'>VINYL</MenuItem>
							<MenuItem value='CD'>CD</MenuItem>
							<MenuItem value='SACD'>Superaudio CD</MenuItem>
							<MenuItem value='CD-GOLD'>CD Gold</MenuItem>
							<MenuItem value='TAPE'>TAPE</MenuItem>
						</Select>

					</FormControl>
					<TextField
						id='Physical.MediaType'
						label='Media Type'
						className={classes.InputField}
						value={InventoryItem.Physical.MediaType}
						onChange={handleNestedChange}
						variant='filled' />
				</form>
			</Card>
			<Card className={classes.Card}>
				<Typography variant='h5'>Price</Typography>
				<form>
					{['DealerPrice', 'RetailPrice', 'SourcePrice'].map(row =>
						<TextField
							id={`Price.${row}`}
							label={row}
							className={classes.InputField}
							value={InventoryItem.Price[row] || 0.00}
							onChange={handleNestedChange}
							type='number'
							variant='filled' />
					)}
				</form>
			</Card>
			<Card className={classes.Card}>
				<Typography variant='h5'>Track</Typography>
				<form></form>
			</Card>
			<Card className={classes.Card}>
				<CardContent>
					<Typography variant='h5'>Flag</Typography>
					<form>
						{['BackOrdered', 'Stale', 'Visible'].map(row =>
							<FormControlLabel
								control={
									<Checkbox
										id={`Flag.${row}`}
										checked={InventoryItem.Flag[row]}
										value={InventoryItem.Flag[row]}
										onChange={handleNestedBoolean}/>
								}
								label={row}
							/>
						)}
					</form>
				</CardContent>
			</Card>
			<Card className={classes.Card}>
				<CardContent>
					<Typography variant='h5'>Trigger</Typography>
					<form>
						<TextField
							id='Trigger.LowInventory'
							label='Low Inventory Trigger'
							className={classes.InputField}
							value={InventoryItem.Trigger.LowInventory} //Settings-Default-LowInventory
							onChange={handleNestedChange}
							variant='filled' />
					</form>
				</CardContent>
			</Card>
			<Card className={classes.Card}>
				<CardContent>
					<Button variant='contained' color='primary' onClick={handleSave}>
						<Save className={classes.Icon}/> Save
					</Button>
				</CardContent>
			</Card>
		</Paper>
	)
}


/* 
Add nested setter to global utilities
Desc to multi line format
Get default values from Settings
Try lodash to remove 2 handlers

Metadata: {
	ImgURL: {},
	SourceURL: '',
},
Track: [{
	Name: '',
	Disk: '',
	Side: '',
	Track: '',
	Length: 0.00,
	Performer: {}
}],
*/