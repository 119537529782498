import firebase from 'firebase/app'       ;
import               'firebase/auth'      ;
import               'firebase/firestore' ;
import               'firebase/storage'   ;

import firebaseConfig from './fb_config';

import { authState           } from 'rxfire/auth'      ;
import { collectionData, doc, docData } from 'rxfire/firestore' ;

import { filter } from 'rxjs/operators';


const app = firebase.initializeApp(firebaseConfig);

const auth      = firebase.auth     (app);
const firestore = firebase.firestore(app);
const storage   = firebase.storage(app);

const loggedIn$ = authState(auth).pipe(filter(user => !!user));

export default firebase;
export { app, auth, firestore, storage, collectionData, loggedIn$, docData };