import   React          from 'react'                          ;
import { makeStyles, useTheme   } from '@material-ui/core/styles'       ;
import   List           from '@material-ui/core/List'         ;
import   ListItem       from '@material-ui/core/ListItem'     ;
import   ListItemIcon   from '@material-ui/core/ListItemIcon' ;
import   ListItemText   from '@material-ui/core/ListItemText' ;
import   Collapse       from '@material-ui/core/Collapse'     ;
import {
    Home,
    BarChart,
    Person,
    RestorePage,
    Description,
    Settings,
    StarBorder,
    ExpandLess,
    ExpandMore
} from '@material-ui/icons';
import {    FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTools,
    faCompactDisc,
    faBoxOpen,
    faCartArrowDown
} from '@fortawesome/free-solid-svg-icons';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
	},
	selected: {
		backgroundColor: 'lightgrey',
		fontWeight: 900,
		color: 'black',
		boxShadow: '5px 5px 10px #9E9E9E',
		position: 'relative',
		transform: 'translate(-5px,-15px)',
		transition: 'all 300ms',
	}
}));


export default function NestedList() {
	const theme = useTheme();
	const classes                             = useStyles();
    const [expandInvoices, setexpandInvoices] = React.useState(true);
    const [expandDiscog, setexpandDiscog]     = React.useState(false);
    const [current, setcurrent]               = React.useState('InvoicesMake');

	let NavItem = (text, icon, link) => (
		<ListItem
			button 
			onClick   = { () => setcurrent(link) }
			className = { current === link ? classes.selected : null}
			color     = 'primary' >
			<ListItemIcon >{ icon }</ListItemIcon>
			<ListItemText 
				primary = { text }
				style   = { {fontWeight: 900} } />
		</ListItem>
	);

	let NavItemListed = (text, icon, link) => (
		<ListItem
			button
			onClick   = { () => setcurrent(link) }
			className = { current === link ? classes.selected : classes.nested }>
			<ListItemIcon>{ icon }</ListItemIcon>
			<ListItemText
				primary = { text }
				style   = { {fontWeight: 900} }/>
		</ListItem>
	);

	let FAIcon = (name, size = 'lg') => <FontAwesomeIcon icon = {name} size = {size} fixedWidth />;

    return (
        <List
			component       = 'nav'
			aria-labelledby = 'nested-list-subheader'
			className       = {classes.root} >

			{ NavItem('Home'           , <Home        />              , 'Home'         ) }
			{ NavItem('Data'           , <BarChart    />              , 'Data'         ) }
			{ NavItem('Inventory'      ,  FAIcon     (faBoxOpen)      , 'Inventory'    ) }
			{ NavItem('Customers'      , <Person      />              , 'Customers'    ) }
			{ NavItem('Back Orders'    , <RestorePage />              , 'BackOrder'    ) }
			{ NavItem('Purchase Orders',  FAIcon     (faCartArrowDown), 'PurchaseOrder') }
		
			<ListItem 
				button
				onClick   = { () => {setexpandInvoices(!expandInvoices); setcurrent('Invoices');} }
				className = { (current === 'Invoices' && expandInvoices === true) ? classes.selected : null} >
				<ListItemIcon><Description /></ListItemIcon>
				<ListItemText primary='Invoices' />
				{ expandInvoices ? <ExpandLess /> : <ExpandMore /> }
			</ListItem>
			<Collapse 
				unmountOnExit
				in      = {expandInvoices}
				timeout = 'auto' >
				<List disablePadding component = 'div' >
					{NavItemListed('View Invoices', <StarBorder />, 'InvoicesView')}
					{NavItemListed('Make Invoices', <StarBorder />, 'InvoicesMake')}
					{NavItemListed('Edit Invoices', <StarBorder />, 'InvoicesEdit')}
				</List>
			</Collapse>
	
			<ListItem
				button
				onClick   = { () => {setexpandDiscog(!expandDiscog); setcurrent('Discog');} }
				className = { (current === 'Discog' && expandDiscog === true) && classes.selected } >
				<ListItemIcon>
					<FontAwesomeIcon
						icon = {faCompactDisc}
						size = 'lg'
						fixedWidth
					/>
				</ListItemIcon>
				<ListItemText primary='Discog' />
				{expandDiscog ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={expandDiscog} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					{NavItemListed('Discog Listings', <StarBorder />, 'DiscogListings')}
					{NavItemListed('Discog Orders'  , <StarBorder />, 'DiscogOrders'  )}
					{NavItemListed('Discog Invoices', <StarBorder />, 'DiscogInvoices')}
				</List>
			</Collapse>
			
		{NavItem('Utilites', <FontAwesomeIcon icon={faTools} size='lg' fixedWidth />, 'Utilities')}
		{NavItem('Settings', <Settings                                            />, 'Settings' )}
		</List>
    );
}