import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';



import {collectionData, firestore} from 'utilities/Firebase';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	}
}));

const SettingsRef = firestore.collection('Settings');
const Settings$ = collectionData(SettingsRef, 'id');


const DefaultDoc  = SettingsRef.doc('Default');
const TaxDoc      = SettingsRef.doc('Tax');
const TriggerDoc  = SettingsRef.doc('Trigger');

export default function Settings(props) {

	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const defaultRow = () => (
		<div>
			<Typography>Invoice Number:
				<TextField id='InvoiceNumber' variant="filled" />
			</Typography>
			<Typography>Low Inventory Number:
				<TextField id='LowInventory' variant="filled" />
			</Typography>
			<Typography>Visible:
				<Checkbox
					checked={false}
					onChange={handleChange('checkedA')}
					value="checkedA"/>
			</Typography>
		</div>
	);

	const taxRow = () => (
		<div>
			Grid Table
		</div>
	);

	const triggerRow = () => (
		<div>
			<Typography>Back Order Late Trigger:
				<TextField id='BackOrderTrigger' variant="filled" />
			</Typography>
			<Typography>Fulfillment Late Trigger:
				<TextField id='FulfillmentTrigger' variant="filled" />
			</Typography>
			<Typography>Stale Inventory Trigger:
				<TextField id='StaleTimer' variant="filled" />
			</Typography>
		</div>
	);

	const ExpPanel = (idx, Title, Desc, Comp) => (
		<ExpansionPanel
			expanded={expanded === `panel${idx}`}
			onChange={handleChange(`panel${idx}`)}>
				<ExpansionPanelSummary
					id='panel1bh-header'
					expandIcon={<ExpandMoreIcon />}>
					<Typography className={classes.heading}>{Title}</Typography>
					<Typography className={classes.secondaryHeading}>{Desc}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>{() => Comp}</ExpansionPanelDetails>
		</ExpansionPanel>
	)
	
	const settings = [
		{
			Title : 'Default Settings',
			Desc  : 'Default values for inventory.',
			Comp  : () => defaultRow()
		},
		{
			Title : 'Tax Settings',
			Desc  : 'Tax keys and Tax values.',
			Comp  : () => taxRow()
		},
		{
			Title : 'Trigger Settings',
			Desc  : 'Default trigger values.',
			Comp  : () => triggerRow()
		},
	].map((row, idx) => ExpPanel(idx, row.Title, row.Desc, row.Comp));

	return (
		<div className={classes.root}>
			{settings}
		</div>
	);
}


/* 
	
Default
	InvoiceNumber
	LowInventory
	Visible

Tax
	Code [BC-CA]
		Field1: Val1,
		Field2: Val2,
		...,
		FieldN: ValN
Trigger
	BackOrderTrigger
	Fulfillment Trigger
	Stale Timer

	<ExpansionPanel
		expanded={expanded === 'panel1'}
		onChange={handleChange('panel1')}>
		<ExpansionPanelSummary
			expandIcon={<ExpandMoreIcon />}
			id='panel1bh-header'>
			<Typography className={classes.heading}>
				Default Settings
			</Typography>
			<Typography className={classes.secondaryHeading}>
				Default values for inventory.
			</Typography>
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>
			<Typography>Some Dropdowns</Typography>
		</ExpansionPanelDetails>
	</ExpansionPanel>
*/