import   React             , {Component } from 'react'                  ;
import { loggedIn$         }              from 'utilities/Firebase'     ;
import './App.css';

import   ResponsiveDrawers                from 'nav/ResponsiveDrawer'   ;
import   UserLogin                        from 'common/utils/UserLogin' ;
import NewIndex from 'newIndex';
import Index2 from 'index2';


export default class App extends Component {
	state = {
		CurrentSelection: null,
		User: null
	};

	async authHandler(authData) { this.setUser(authData.user) }
	setUser = user => {this.setState({User: user})}

	componentDidMount() {
		loggedIn$.subscribe( user => {
			this.authHandler({ user });
			const { displayName, email, phoneNumber, photoURL } = user;
		})
	}

	render() {
		return (
			<div className='App'>
				<UserLogin
					User        = { this.state.User  }
					authHandler = { this.authHandler }
					setUser     = { this.setUser     }
				/>

				{/* <ResponsiveDrawers /> */}
				<NewIndex/>
				{/* <Index2/> */}
				
			</div> 
		)
	}
}
