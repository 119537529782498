import React, { useState, useEffect } from 'react'
import { Paper, Card, CardContent, Typography, TextField } from '@material-ui/core';
import { Company$ } from 'utilities/Streams';
import { map } from 'rxjs/operators';
import ComponentStyle from './styles';

const useStyles = ComponentStyle;

export function CustomerDetails({ match }) {
	const classes = useStyles();

	const [Company, setCompany] = useState({});
	
	useEffect(() => {
		let CompanySub = Company$.subscribe(res => setCompany(res.find(company => company.CompanyID === match.params.CompanyID)));
		return (() => CompanySub.unsubscribe());
	}, [])

	const handleChange = req => console.log('req', req.target);


	const KeyValues = (Type, TypeString) => {
		
		
		return ([
		{
			key: Type.Name.First,
			name: 'First Name',
			onChange: evt => {
				let inner = Company;
				inner[TypeString]['Name']['First'] = evt.target.value;
				setCompany({...inner});
				/* setCompany({...Company, [TypeString]: {
					{...[`Company.${TypeString}.Name`]},
					Name: {
						...[`Company.${TypeString}.Name`],
						First: 'a'
					}
				}}) */
				/* [Company]['Name']['First'] */
			},
		},
		{
			key: Type.Name.Last,
			name: 'Last Name',
			onChange: evt => {
				let inner = Company;
				inner[TypeString]['Name']['Last'] = evt.target.value;
				setCompany({...inner});
			}
		},
		{
			key: Type.Address,
			name: 'Address',
			onChange: evt => setCompany({...Company, ['BillTo.Address']: evt.target.value}),
		},
		{
			key: Type.City,
			name: 'City',
			onChange: evt => setCompany({...Company, City: evt.target.value}),
		},
		{
			key: Type.ProvinceState,
			name: 'Province / State',
			onChange: evt => setCompany({...Company, ProvinceState: evt.target.value}),
		},
		{
			key: Type.Country,
			name: 'Country',
			onChange: evt => setCompany({...Company, Country: evt.target.value}),
		},
		{
			key: Type.PostalCode,
			name: 'PostalCode',
			onChange: evt => setCompany({...Company, PostalCode: evt.target.value}),
		},
	])}


	const FieldGroup = (Type, TypeString) => (
		KeyValues(Type, TypeString).map(row => 
			<>
				<Typography>
					{row.name}
				</Typography>
				<TextField
					className={classes.TextField}
					label={row.name}
					value={row.key}
					id={row.key}
					onChange={row.onChange}
					margin="normal"
					variant="filled" />
			</>
		)
	)

	const BillToCard = _Company => {
		const { BillTo } = _Company;
		return (BillTo &&
			<Card>
				<CardContent>{FieldGroup(BillTo, 'BillTo')}</CardContent>
			</Card>
		)
	}

	const ShipToCard = _Company => {
		const {ShipTo} = _Company;
		return (ShipTo &&
			<Card>
				<CardContent>{FieldGroup(ShipTo, 'ShipTo')}</CardContent>
				{/* <CardContent>
					<Typography>Ship To</Typography>
					<TextField
						id="outlined-name"
						label="First Name"
						value={ShipTo.Name.First}
						margin="normal"
						variant="outlined"/>
					<TextField
						id="outlined-name"
						label="Last Name"
						value={ShipTo.Name.Last}
						onChange={handleChange('ShipTo:{Name:{First:}}')}
						margin="normal"
						variant="outlined"/>
					<TextField
						id="outlined-name"
						label="Address"
						value={ShipTo.Address}
						onChange={handleChange('Company.ShipTo.Address')}
						margin="normal"
						variant="outlined"/>
					<TextField
						id="outlined-name"
						label="City"
						value={ShipTo.Name.Last}
						onChange={handleChange('ShipTo.City')}
						margin="normal"
						variant="outlined"/>
					<TextField
						id="outlined-name"
						label="Province/State"
						value={ShipTo.Province}
						onChange={handleChange('ShipTo.Province')}
						margin="normal"
						variant="outlined"/>
					<TextField
						id="outlined-name"
						label="Country"
						value={ShipTo.Country}
						onChange={handleChange('ShipTo.Country')}
						margin="normal"
						variant="outlined"/>
					<TextField
						id="outlined-name"
						label="Postal Code"
						value={ShipTo.PostalCode}
						onChange={handleChange('ShipTo.PostalCode')}
						margin="normal"
						variant="outlined"/>
				</CardContent> */}
			</Card>
		)
	}

	
	return (
		<div>
			<Paper>
				{Object.keys(Company).length > 0 &&
					(<>
						{console.log(Company)}
						{BillToCard(Company)}
						{ShipToCard(Company)}
					</>
				)}

			</Paper>

			<h3>ID: {match.params.CompanyID}</h3>
		</div>
	)
}