import React    , { useState, useEffect } from 'react'                    ;
import firebase , { app      } from 'utilities/Firebase' ;
import Logo from '../media/logo.png';


import { makeStyles } from '@material-ui/core/styles' ;
import { Typography } from '@material-ui/core'        ;
import   Button       from '@material-ui/core/Button' ;
import   Modal        from '@material-ui/core/Modal'  ;

import Card  from '@material-ui/core/Card' ;
import Grid  from '@material-ui/core/Grid' ;
import Paper from '@material-ui/core/Paper';

import   EmailIcon              from '@material-ui/icons/Email'  ;
import   VpnKeyIcon             from '@material-ui/icons/VpnKey' ;


const useStyles = makeStyles(theme => ({
	root: {
		fontFamily: 'Times, serif',
		color : theme.palette.text.primary,
	},
	Cover: {
		height         : '100%',
		justifyContent : 'center',
	},
	LoginCard: {
		position  : 'fixed',
		top       : '30%',
		boxShadow : '0px 0px 20px -14px',
		borderRadius: '5px',
		textAlign : 'center',
		padding   : '20px 10px',
		width     : '15%',
		minWidth  : '260px',
	},
	Logo: {
		margin: '5px 0'
	},
	Subtitle : {
		fontSize: '1.3em',
		fontFamily: 'Times, serif',
		letterSpacing: '2px',
		margin: '0 0 40px 0'
	},
	LoginRow: {
		color     : theme.palette.text.primary,
		boxSizing : 'border-box',
		margin    : '10px 20px',
		width     : '90%'
	},
	RowIcon: {
		color       : theme.palette.text.primary,
		marginRight : '5px',
	},
	RowInput: {
		color : theme.palette.text.primary,
		fontFamily: 'Times, serif',
		letterSpacing: '1px',
		borderWidth:  '0 0 1px',
		padding: '2px 7px'
	},
	ButtonLogin: {
		fontWeight : 'bold',
		width: '50%',
		letterSpacing: '2px',
		fontFamily: 'Times, serif',
		backgroundColor: 'black',
		color: 'white',
		margin     : '5px 2px',
		marginTop  : '15px',
		borderRadius: '0'
	},
	
	
	
	Text: {
		width    : '90%',
		display  : 'block',
		position : 'relative',
		margin   : '0 auto',
	},
	Error: {
		color    : 'red',
		fontSize : '0.7rem',
	},
	



	Circle: {
		width        : '100px',
		height       : '100px',
		background   : 'red'  ,
		borderRadius : '50%'  ,
	},
}));

export default function UserLogin(props) {
	const classes = useStyles();
	const User    = props.User;
	
	const [Email    , setEmail    ] = useState('');
	const [Password , setPassword ] = useState('');
	const [Err      , setErr      ] = useState('');

	const [IsDisabled, setIsDisabled] = useState(false);

	const handleEmailChange    = (e) => { setEmail   (e.target.value) }
	const handlePasswordChange = (e) => { setPassword(e.target.value) }
	
	const signIn = async () => {
		setIsDisabled(true);
		try {
			const user = await app.auth().signInWithEmailAndPassword(Email, Password);
			const token = await app.auth().currentUser.getIdTokenResult;
			if (!!token.claims.admin) {
				await props.authHandler(user)
			}
			setIsDisabled(false);
		} catch (error) {
			setErr(error.message);
			setIsDisabled(false);
		}
	}

	const signOut = async () => {
		await firebase.auth().signOut();
		this.props.setUser(null);
	} // Load [User, firebase] prop ot nav drawer

	return (
		!User &&
		(
			<Modal open = {true}>							
				<Paper className = {classes.Cover}>
					<Grid
						container
						direction  = "column"
						justify    = "center"
						alignItems = "center" >
						<Card className = {classes.LoginCard}>
							{/* <div className={classes.Circle}>
							</div> */}
							<img className={classes.Logo} src = {Logo}/>
							<Typography className={classes.Subtitle}>Dashboard</Typography>
							<div className = {classes.LoginRow}>
								<label 
									className = {classes.RowIcon}
									htmlFor   = "Email" >
									📧
								</label>
								<input 
									className = {classes.RowInput}
									type      = "text"
									name      = "Email"
									id        = "email"
									value     = { Email }
									onChange  = { handleEmailChange }
									placeholder = 'Email' />
							</div>
							<div className = {classes.LoginRow}>
								<label 
									className = {classes.RowIcon}
									htmlFor   = "Password">
									🔑
								</label>
								<input 
									className = {classes.RowInput}
									type      = "password"
									name 	  = "Password"
									id        = "password"
									value     = { Password }
									onChange  = { handlePasswordChange }
									placeholder = 'Password' />
							</div>
							<div className = {classes.Text}>
								<Typography className = {classes.Error}>{Err}</Typography>
							</div>
							<div className = {classes.LoginRow}>
								{IsDisabled ?
								(
									<Button 
										id        = 'loginButton'
										variant   = 'contained'
										color     = 'primary'
										className = {classes.ButtonLogin}
										disabled  = {true} >
										Login
									</Button>
								) : (
									<Button 
										id        = 'loginButton'
										variant   = 'contained'
										color     = 'primary'
										className = {classes.ButtonLogin}
										onClick   = {signIn} >
										Login
									</Button>
								)
								}
							</div>

							
						</Card>
					</Grid>
				</Paper>
			</Modal>
		)
	)
}


/* 
	If you want to write it to the DOM, pass a string instead: disbled="false" or disbled={value.toString()}.
	If you used to conditionally omit it with disbled={condition && value}, pass disbled={condition ? value : undefined} instead.

  const [Toggle, setToggle] = useState(false);
  const [Div, setDiv] = useState(false);

  useEffect(() => {
    setDiv(Toggle);
  }, [Toggle]);

  const toggleButton = () => {
    setToggle(Toggle => !Toggle);
    setTimeout(() => setToggle(Toggle => !Toggle), 500);
  };

  return Div ? (
    <div>
      <button onClick={toggleButton} disabled={true}>
        Hi
      </button>
    </div>
  ) : (
    <div>
      <button onClick={toggleButton} disabled={false}>
        Hi
      </button>
    </div>
  );
 */