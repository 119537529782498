import React     from 'react'     ;
import PropTypes from 'prop-types';

import NestedList  from '../common/nav/NestedList'      ;

import   AppBar                   from '@material-ui/core/AppBar'      ;
import   CssBaseline              from '@material-ui/core/CssBaseline' ;
import   Divider                  from '@material-ui/core/Divider'     ;
import   Drawer                   from '@material-ui/core/Drawer'      ;
import   Hidden                   from '@material-ui/core/Hidden'      ;
import   IconButton               from '@material-ui/core/IconButton'  ;
import   Toolbar                  from '@material-ui/core/Toolbar'     ;
import   Typography               from '@material-ui/core/Typography'  ;
import { makeStyles  , useTheme } from '@material-ui/core/styles'      ;

import MenuIcon from '@material-ui/icons/Menu';


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display : 'flex'
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width      : drawerWidth,
			flexShrink : 0
		}
	},
	appBar: {
		marginLeft                   : drawerWidth,
		[theme.breakpoints.up('sm')] : {
			width : `calc(100% - ${drawerWidth}px)`
		}
	},
	menuButton: {
		marginRight                  : theme.spacing(2),
		[theme.breakpoints.up('sm')] : {
			display : 'none'
		}
	},
	toolbar     : theme.mixins.toolbar,
	drawerPaper : {
		width : drawerWidth
	},
	content: {
		flexGrow : 1,
		padding  : theme.spacing(3)
	},
	nestedList: {
		textAlign: 'left'
	}
}));

export default function ResponsiveDrawer(props) {
	const { container }               = props;

	const classes                     = useStyles();
	const theme                       = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle          = () => setMobileOpen(!mobileOpen);

	const drawer = (
		<div>
			<div className = {classes.toolbar} />
			<NestedList />

			{/* <List>
				{["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
				<ListItem button key={text}>
					<ListItemIcon>
					{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
					</ListItemIcon>
					<ListItemText primary={text} />
				</ListItem>
				))}
			</List> */}
			{/* <Divider /> */}			
		</div>
	);

	return (
		<div className = {classes.root}>
			<CssBaseline />

			<nav 
				className  = {classes.drawer}
				aria-label = 'Navigation Side Panel' >
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation='css'>
					<Drawer
						container  = {container}
						variant    = 'temporary'
						anchor     = {theme.direction === 'rtl' ? 'right' : 'left'}
						open       = {mobileOpen}
						onClose    = {handleDrawerToggle}
						classes    = {{ paper: classes.drawerPaper }}
						ModalProps = {{ keepMounted: true }} >                         // Better open performance on mobile.
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation='css'>
					<Drawer
						classes = {{ paper: classes.drawerPaper }}
						variant = 'permanent'
						open >
						{drawer}
					</Drawer>
				</Hidden>
			</nav>

			<AppBar 
				position = 'fixed'
				className = {classes.appBar} >
				<Toolbar>
					<IconButton
						color      = 'inherit'
						aria-label = 'Open drawer'
						edge       = 'start'
						onClick    = {handleDrawerToggle}
						className  = {classes.menuButton} >
						<MenuIcon />
					</IconButton>
					<Typography variant = 'h6' noWrap>
						Make Invoice
					</Typography>
				</Toolbar>
			</AppBar>

			<main 
				className = {classes.content}
				style     = {{ textAlign: 'left' }} >
				<div className = {classes.toolbar} />
			</main>

		</div>
	);
}

ResponsiveDrawer.propTypes = {
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object
};
