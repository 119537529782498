import { firestore } from 'utilities/Firebase';

//{ Collection Refs
const BackOrderCollectionRef     = firestore.collection('BackOrder'    );
const CompanyCollectionRef       = firestore.collection('Company'      );
const CoverCollectionRef         = firestore.collection('Cover'        );
const GenerateCollectionRef      = firestore.collection('Generate'     );
const InventoryCollectionRef     = firestore.collection('Inventory'    );
const InvoiceCollectionRef       = firestore.collection('Invoice'      );
const LinkCollectionRef          = firestore.collection('Link'         );
const PurchaseOrderCollectionRef = firestore.collection('PurchaseOrder');
const SettingCollectionRef       = firestore.collection('Setting'      );

const InvoiceID_PurchaseOrderRef   = firestore.collection('Link').doc('InvoiceID-PurchaseOrderID');
const InvoiceNumber_InvoiceIDRef   = firestore.collection('Link').doc('InvoiceNumber-InvoiceID'  );
const InvoiceNumber_InvoicePathRef = firestore.collection('Link').doc('InvoiceNumber_InvoicePath');

const SettingsDefaultRef = firestore.collection('Setting').doc('Default');
const SettingsTriggerRef = firestore.collection('Setting').doc('Trigger');

const TaxRateRef = firestore.collection('Setting').doc('Tax');
//}

export {
	BackOrderCollectionRef     ,
	CompanyCollectionRef       ,
	CoverCollectionRef         ,
	GenerateCollectionRef      ,
	InventoryCollectionRef     ,
	InvoiceCollectionRef       ,
	LinkCollectionRef          ,
	PurchaseOrderCollectionRef ,
	SettingCollectionRef       ,
	SettingsDefaultRef         ,
	SettingsTriggerRef
};

export {
	TaxRateRef,
	InvoiceID_PurchaseOrderRef  ,
	InvoiceNumber_InvoiceIDRef  ,
	InvoiceNumber_InvoicePathRef,
}