import { makeStyles } from "@material-ui/styles";

const ComponentStyle = makeStyles(theme =>  ({
	root: {
		padding: '1rem',
	},

	fab: {
		position: 'fixed',
		bottom: '1.5rem',
		right: '1.5rem',
	},

	Card: {
		margin: '1rem',
		padding: '0.5rem',
	},

	InputField: {
		margin: '1rem',
	},

	Icon: {
		marginRight: '0.3rem',
	},

		FilledInput : {
			width: '100px',
			textAlign: 'right',
			margin:0
		},

		CompanyTextArea : {
			width: '500px',
		},

		CardPanel : {
			margin: '1rem',
			display: 'flex'
		},

		CartTable: {
			tableLayout: 'auto'
		},

		CartFieldInteger: {
			width: '3rem',
			textAlign: 'center',
		},

		CartFieldFloat: {
			width: '7rem',
		},

		RightIcon: {
			marginLeft: '0.7rem'
		},

		Button: {
			margin: '0.5rem'
		},

		Badge: {
			top: '0.6rem',
			right: '0.2rem'
		},


		CardFieldWide: {
			width: '6rem'
		},

		FieldRightAlign: {
			textAlign: 'right'
		}
	})
)

export default ComponentStyle;