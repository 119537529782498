import React, { useState, useEffect } from 'react'
import { Company$ } from 'utilities/Streams';
import { TableCell, TableRow, TableHead, Table, Paper, TableBody, Tooltip, IconButton } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import {Link} from 'react-router-dom';



const head = <TableHead>
	<TableRow>
		<TableCell>Company ID</TableCell>
		<TableCell>Name</TableCell>
		<TableCell>Email</TableCell>
		<TableCell>Details</TableCell>
	</TableRow>
</TableHead>;

const body = customer => customer.map(({ CompanyID, Name, Email  }) => (
	<TableBody>
		<TableRow>
			<TableCell>{CompanyID}</TableCell>
			<TableCell>{Name.First + ' ' + Name.Last}</TableCell>
			<TableCell>{Email}</TableCell>
			<TableCell>
				<Tooltip title='More Details' placement='top'>
					<Link to={`/Customers/${CompanyID}`}><IconButton><KeyboardArrowRight /></IconButton></Link>
				</Tooltip>
			</TableCell>
		</TableRow>
	</TableBody>
)); 

export default function Customers() {
	const [Company, setCompany] = useState([]);
	useEffect(() => { Company$.subscribe(res => setCompany(res)); }, [])
	console.log(Company);
	return (
		<div>
			<Paper>
				<Table>
					{head}
					{body(Company)}
				</Table>
			</Paper>
		</div>
	)
}
