import { firestore } from 'utilities/Firebase';
import { Inventory$ } from 'utilities/Streams';


//Check if overwriting first

const SaveInventory = async InventoryItem => {
	let InventorySub = Inventory$.subscribe(async res => {
		const existsQ = InventoryCheck(InventoryItem.ID, res);
		InventorySub.unsubscribe();
		if (!existsQ) {
			console.log(`New Item ${InventoryItem.ID}`);
			await firestore.collection('Inventory').doc(InventoryItem.ID).set(InventoryItem);
			console.log('Set Complete');
		} else throw `ItemID ${InventoryItem.ID} already exists.`;
		
	});
}

const InventoryCheck = (ItemID, InventoryList) => InventoryList.find(item => item.ID === ItemID)

export default SaveInventory;